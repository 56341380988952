import React from 'react';
import GridDuck from 'gridduck';
import {DemandListCell, List, StandardListCell, StateListCell, DummyListCell, EditableList, SensorDataListCell} from "gd-react";
import history from "../../meta/history";
class GroupAssetList extends React.Component {
	constructor(props) {
		super(props);
		this.getList = this.getList.bind(this);
		this.goToAsset = this.goToAsset.bind(this);
		this.getAvailableList = this.getAvailableList.bind(this);
		this.save = this.save.bind(this);
		let self = this;
		this.state = {}
		this.filters = [
			{
				field: 'deviceGroupId',
				value: this.props.item.id
			}
		];
	}

	componentDidMount() {
		let self = this;
		GridDuck.getAccount({id: GridDuck.userId})
			.then(function (user) {
				console.log(user.orgPermission, ' : user org permission')
				self.setState({orgPerm: user.orgPermission});
			});
	}

	save(newSites, itemsToAddRemove) {
		let newAssetIds = this.props.item.assetIds.filter(siteId => !itemsToAddRemove.itemsToRemove.find(i => i.id === siteId)).concat(itemsToAddRemove.itemsToAdd.map(i => i.id));
		return this.props.item.setAssetIds(newAssetIds);
	}

	getList(retrievalParams) {
		let paramsFromComponent = retrievalParams.filters || []
		retrievalParams.filters = paramsFromComponent.concat(this.filters || []);
		return GridDuck.getAssets(retrievalParams);
	}

	goToAsset(asset) {
		history.push("/device/" + asset.id);
	}

	getAvailableList(retrievalParams) {
		return GridDuck.getAssets(retrievalParams);
	}

	render() {
		let assets;
		if (this.props.item && this.state.orgPerm && this.props.loaded) {
			assets = (
				<EditableList
					asyncActive
					activeColumns={[
						{component: StandardListCell, bold: true, sortable: true, title: 'Name', field: 'name'},
						{component: StandardListCell, title: 'Site Name', field: 'siteName'},
						{component: StateListCell, sortable: false, title: 'Status', field: 'currentState', showLabel: true}
					]}
					getActiveList={this.getList}
					getAvailableList={this.getAvailableList}
					save={this.save}
					disabled={!(this.props.item._permission ==='edit' || this.props.item._permission ==='admin') || this.state.orgPerm === 'view'}
					rowOnClick={this.goToAsset}
					parentField={"parentAsset"}
					childrenField={"children"}
					expanded={true}
					filters={this.filters}
				/>
			)
		}
		return (
			<div className='page'>
				{assets}
			</div>
		);
	}
}

export default GroupAssetList;
