"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataTypeTypes = exports.influxLoadTypes = exports.LoadTypes = exports.DataCategoryTypes = exports.HubConnections = exports.Connections = exports.Units = exports.DataTypes = exports.DataCategories = exports.GatewayOptions = exports.DeviceOptions = exports.StateOptions = void 0;
var HubConnections;
(function (HubConnections) {
    HubConnections["ETHERNET"] = "ethernet";
    HubConnections["WIFI"] = "wifi";
    HubConnections["MOBILE"] = "mobile";
})(HubConnections || (exports.HubConnections = HubConnections = {}));
var Connections;
(function (Connections) {
    Connections["NB_IOT"] = "NB-IoT";
    Connections["BLUETOOTH"] = "Bluetooth";
    Connections["ZIGBEE"] = "Zigbee";
    Connections["API"] = "API";
    Connections["EMLITE"] = "Emlite";
    Connections["PRESSAC"] = "Pressac";
    Connections["POWER_RADAR"] = "Power Radar";
    Connections["SOLAR_EDGE"] = "Solar Edge";
    Connections["VIRTUAL"] = "Virtual";
    Connections["MODBUS_RTU"] = "Modbus RTU";
})(Connections || (exports.Connections = Connections = {}));
var DataCategoryTypes;
(function (DataCategoryTypes) {
    DataCategoryTypes["ELECTRICITY"] = "ELECTRICITY";
    DataCategoryTypes["CURRENT"] = "CURRENT";
    DataCategoryTypes["WATER"] = "WATER";
    DataCategoryTypes["HOT_WATER"] = "HOT_WATER";
    DataCategoryTypes["GAS"] = "GAS";
    DataCategoryTypes["GENERATION"] = "GENERATION";
    DataCategoryTypes["SIGNAL"] = "SIGNAL";
    DataCategoryTypes["TEMPERATURE"] = "TEMPERATURE";
    DataCategoryTypes["INDOOR_AIR_QUALITY"] = "INDOOR_AIR_QUALITY";
    DataCategoryTypes["CO2"] = "CO2";
    DataCategoryTypes["PRESSURE"] = "PRESSURE";
    DataCategoryTypes["HUMIDITY"] = "HUMIDITY";
    DataCategoryTypes["OCCUPANCY"] = "OCCUPANCY";
    DataCategoryTypes["OPEN_CLOSED"] = "OPEN_CLOSED";
    DataCategoryTypes["LIGHT"] = "LIGHT";
    DataCategoryTypes["BATTERY"] = "BATTERY";
    DataCategoryTypes["EXPORTED"] = "EXPORTED";
})(DataCategoryTypes || (exports.DataCategoryTypes = DataCategoryTypes = {}));
const DataCategories = [
    {
        id: DataCategoryTypes.ELECTRICITY,
        name: 'Electricity',
        icon: 'FaBolt',
        color: '#FFCA00'
    },
    {
        id: DataCategoryTypes.CURRENT,
        name: 'Current',
        icon: 'FaBolt',
        color: '#FFCA00'
    },
    {
        id: DataCategoryTypes.WATER,
        name: 'Water',
        icon: 'FaTint',
        color: '#00C3EE'
    },
    {
        id: DataCategoryTypes.HOT_WATER,
        name: 'Hot Water',
        icon: 'FaTint',
        color: '#f52ec0'
    },
    {
        id: DataCategoryTypes.GAS,
        name: 'Gas',
        icon: 'FaFire',
        color: '#D768F2'
    },
    {
        id: DataCategoryTypes.GENERATION,
        name: 'Generation',
        icon: 'FaSolarPanel',
        color: '#3ad56e'
    },
    {
        id: DataCategoryTypes.EXPORTED,
        name: 'Export',
        icon: 'FaArrowAltCircleRight',
        color: '#3ad56e'
    },
    {
        id: DataCategoryTypes.SIGNAL,
        name: 'Signal',
        icon: 'FaBroadcastTower'
    },
    {
        id: DataCategoryTypes.TEMPERATURE,
        name: 'Temperature',
        icon: 'FaThermometerThreeQuarters',
        color: '#e88a16'
    },
    {
        id: DataCategoryTypes.INDOOR_AIR_QUALITY,
        name: 'Indoor Air Quality',
        icon: 'FaWind'
    },
    {
        id: DataCategoryTypes.CO2,
        name: 'CO2',
        icon: 'FaWind'
    },
    {
        id: DataCategoryTypes.PRESSURE,
        name: 'Pressure',
        icon: 'FaWeightHanging'
    },
    {
        id: DataCategoryTypes.HUMIDITY,
        name: 'Humidity',
        icon: 'FaWater'
    },
    {
        id: DataCategoryTypes.OCCUPANCY,
        name: 'Occupancy',
        icon: 'FaGhost'
    },
    {
        id: DataCategoryTypes.OPEN_CLOSED,
        name: 'Open/Closed',
        icon: 'FaDoorOpen'
    },
    {
        id: DataCategoryTypes.LIGHT,
        name: 'Light',
        icon: 'FaLightbulb'
    },
    {
        id: DataCategoryTypes.BATTERY,
        name: 'Battery',
        icon: 'FaBatteryThreeQuarters'
    }
];
exports.DataCategories = DataCategories;
const Units = [
    {
        id: 'WATT',
        name: 'Power',
        dataType: 'instantaneous',
        unitType: 'metric',
        metaData: { symbol: 'W' },
        unitCategory: 'Energy'
    },
    {
        id: 'WATT_HOUR',
        name: 'Energy',
        dataType: 'aggregate',
        unitType: 'metric',
        metaData: { symbol: 'Wh' },
        unitCategory: 'Energy'
    },
    {
        id: 'AMPERE',
        name: 'Current',
        dataType: 'instantaneous',
        unitType: 'metric',
        metaData: { symbol: 'A' },
        unitCategory: 'Electricity'
    },
    {
        id: 'VOLT',
        name: 'Voltage',
        dataType: 'instantaneous',
        unitType: 'metric',
        metaData: { symbol: 'V' },
        unitCategory: 'Electricity'
    },
    {
        id: 'LENGTH',
        name: 'Length (Metric)',
        dataType: 'aggregate',
        unitType: 'metric',
        metaData: { symbol: 'm' },
        unitCategory: 'Length'
    },
    {
        id: 'LENGTH_IMPERIAL',
        name: 'Length (Imperial)',
        dataType: 'aggregate',
        unitType: 'nonMetric',
        unitCategory: 'Length',
        metaData: {
            symbol: 'in',
            rules: [
                {
                    "id": "base",
                    "scale_of_base": 1,
                    "name": "Inch",
                    "symbol": "in"
                },
                {
                    "id": "1",
                    "scale_of_base": 12,
                    "name": "Foot",
                    "symbol": "ft"
                },
                {
                    "id": "2",
                    "scale_of_base": 36,
                    "name": "Yard",
                    "symbol": "yd"
                },
                {
                    "id": "3",
                    "scale_of_base": 63360,
                    "name": "Mile",
                    "symbol": "mi"
                }
            ]
        }
    },
    {
        id: 'SQUARED_METER',
        name: 'Area (Metric)',
        dataType: 'aggregate',
        unitType: 'metric',
        metaData: { symbol: 'm²' },
        unitCategory: 'Area'
    },
    {
        id: 'AREA_IMPERIAL',
        name: 'Area (Imperial)',
        dataType: 'aggregate',
        unitType: 'nonMetric',
        unitCategory: 'Area',
        metaData: {
            symbol: 'in²',
            rules: [
                {
                    "id": "base",
                    "scale_of_base": 1,
                    "name": "Square Inch",
                    "symbol": "in²"
                },
                {
                    "id": "1",
                    "scale_of_base": 12,
                    "name": "Square Foot",
                    "symbol": "ft²"
                },
                {
                    "id": "2",
                    "scale_of_base": 36,
                    "name": "Square Yard",
                    "symbol": "yd²"
                },
                {
                    "id": "3",
                    "scale_of_base": 63360,
                    "name": "Square Mile",
                    "symbol": "mi²"
                },
                {
                    "id": "4",
                    "scale_of_base": 6272639.35,
                    "name": "Acre",
                    "symbol": "ac²"
                }
            ]
        }
    },
    {
        id: 'CUBIC_METER',
        name: 'Volume (Metric)',
        dataType: 'aggregate',
        unitType: 'metric',
        metaData: { symbol: 'mᶟ' },
        unitCategory: 'Volume'
    },
    {
        id: 'VOLUME_IMPERIAL',
        name: 'Volume (Imperial)',
        dataType: 'aggregate',
        unitType: 'nonMetric',
        unitCategory: 'Volume',
        metaData: {
            symbol: 'in²',
            rules: [
                {
                    "id": "base",
                    "scale_of_base": 1,
                    "name": "Fluid Ounce",
                    "symbol": "fl oz"
                },
                {
                    "id": "1",
                    "scale_of_base": 5,
                    "name": "Gill",
                    "symbol": "gi"
                },
                {
                    "id": "2",
                    "scale_of_base": 20,
                    "name": "Pint",
                    "symbol": "pt"
                },
                {
                    "id": "3",
                    "scale_of_base": 40,
                    "name": "Quart",
                    "symbol": "qt"
                },
                {
                    "id": "4",
                    "scale_of_base": 160,
                    "name": "Gallon",
                    "symbol": "gal"
                }
            ]
        }
    },
    {
        id: 'GRAM',
        name: 'Weight (Metric)',
        dataType: 'aggregate',
        unitType: 'metric',
        metaData: { symbol: 'g' },
        unitCategory: 'Weight'
    },
    {
        id: 'WEIGHT_IMPERIAL',
        name: 'Weight (Imperial)',
        dataType: 'aggregate',
        unitType: 'nonMetric',
        unitCategory: 'Weight',
        metaData: {
            symbol: 'lb',
            rules: [
                {
                    "id": "base",
                    "scale_of_base": 1,
                    "name": "Pound",
                    "symbol": "lb"
                },
                {
                    "id": "1",
                    "scale_of_base": 0.0625,
                    "name": "ounce",
                    "symbol": "oz"
                },
                {
                    "id": "2",
                    "scale_of_base": 14,
                    "name": "Stone",
                    "symbol": "st"
                },
                {
                    "id": "3",
                    "scale_of_base": 28,
                    "name": "Quarter",
                    "symbol": "qr"
                },
                {
                    "id": "4",
                    "scale_of_base": 112,
                    "name": "Hundredweight",
                    "symbol": "cwt"
                },
                {
                    "id": "5",
                    "scale_of_base": 2240,
                    "name": "Ton",
                    "symbol": "t"
                }
            ]
        }
    },
    {
        id: 'TEMPERATURE',
        name: 'Temperature',
        dataType: 'instantaneous',
        unitType: 'dimensionless',
        metaData: { symbol: '°C' },
        unitCategory: 'Environmental'
    },
    {
        id: 'INDOOR_AIR_QUALITY',
        name: 'Indoor Air Quality',
        dataType: 'instantaneous',
        unitType: 'dimensionless',
        metaData: { symbol: 'IAQ' },
        unitCategory: 'Environmental'
    },
    {
        id: 'CO2',
        name: 'Carbon Dioxide',
        dataType: 'instantaneous',
        unitType: 'dimensionless',
        metaData: { symbol: 'ppm' },
        unitCategory: 'Environmental'
    },
    {
        id: 'PRESSURE',
        name: 'Pressure',
        dataType: 'instantaneous',
        unitType: 'dimensionless',
        metaData: { symbol: 'hPa' },
        unitCategory: 'Environmental'
    },
    {
        id: 'OCCUPANCY',
        name: 'Occupied/Unoccupied',
        dataType: 'instantaneous',
        unitType: 'boolean',
        metaData: {
            symbol: '0/1',
            categories: [{ "id": "true", "value": 1, "label": "Occupied" }, {
                    "id": "false",
                    "value": 0,
                    "label": "Unoccupied"
                }]
        },
        unitCategory: 'Environmental'
    },
    {
        id: 'LUMINANCE',
        name: 'Luminance',
        dataType: 'instantaneous',
        unitType: 'metric',
        metaData: { symbol: 'lx' },
        unitCategory: 'Environmental'
    },
    {
        id: 'PERCENTAGE',
        name: 'Percentage',
        dataType: 'instantaneous',
        unitType: 'dimensionless',
        metaData: { symbol: '%' },
        unitCategory: 'Special'
    },
    {
        id: 'DECIBEL',
        name: 'Decibel',
        dataType: 'instantaneous',
        unitType: 'dimensionless',
        metaData: { symbol: 'dB' },
        unitCategory: 'Special'
    },
    {
        id: 'CURRENCY',
        name: 'Currency',
        dataType: 'aggregate',
        unitType: 'currency',
        metaData: { symbol: 'p' },
        unitCategory: 'Special'
    },
    {
        id: 'TIME',
        name: 'Time',
        dataType: 'aggregate',
        unitType: 'nonMetric',
        metaData: {
            symbol: 'h',
            rules: [{
                    "id": "1",
                    "scale_of_base": 0.001,
                    "name": "Millisecond",
                    "symbol": "ms"
                }, {
                    "id": "base",
                    "scale_of_base": 1,
                    "name": "Second",
                    "symbol": "s"
                }, {
                    "id": "2",
                    "scale_of_base": 60,
                    "name": "Minute",
                    "symbol": "min"
                }, {
                    "id": "3",
                    "scale_of_base": 3600,
                    "name": "Hour",
                    "symbol": "h"
                }, {
                    "id": "4",
                    "scale_of_base": 604800,
                    "name": "Week",
                    "symbol": "wk"
                }, {
                    "id": "5",
                    "scale_of_base": 2629746,
                    "name": "Month (Aprox.)",
                    "symbol": "mo"
                }, {
                    "id": "6",
                    "scale_of_base": 31536000,
                    "name": "Year",
                    "symbol": "yr"
                }]
        },
        unitCategory: 'Special'
    },
    {
        id: 'OPEN_CLOSED',
        name: 'Open/Closed',
        dataType: 'instantaneous',
        unitType: 'boolean',
        metaData: {
            symbol: '0/1',
            categories: [{ "id": "true", "value": 1, "label": "Open" }, {
                    "id": "false",
                    "value": 0,
                    "label": "Closed"
                }]
        },
        unitCategory: 'Special'
    },
    {
        id: 'TRUE_FALSE',
        name: 'True/False',
        dataType: 'instantaneous',
        unitType: 'boolean',
        metaData: {
            symbol: '0/1',
            categories: [{ "id": "true", "value": 1, "label": "True" }, {
                    "id": "false",
                    "value": 0,
                    "label": "False"
                }]
        },
        unitCategory: 'Special'
    }
];
exports.Units = Units;
var LoadTypes;
(function (LoadTypes) {
    LoadTypes[LoadTypes["CONSUMPTION_ONLY"] = 0] = "CONSUMPTION_ONLY";
    LoadTypes[LoadTypes["GENERATION_ONLY"] = 1] = "GENERATION_ONLY";
    LoadTypes[LoadTypes["CONSUMPTION_EXPORT"] = 2] = "CONSUMPTION_EXPORT";
})(LoadTypes || (exports.LoadTypes = LoadTypes = {}));
var influxLoadTypes;
(function (influxLoadTypes) {
    influxLoadTypes["DELIVERED"] = "DELIVERED";
    influxLoadTypes["GENERATED"] = "GENERATED";
    influxLoadTypes["CONSUMPTION"] = "CONSUMPTION";
})(influxLoadTypes || (exports.influxLoadTypes = influxLoadTypes = {}));
var DataTypeTypes;
(function (DataTypeTypes) {
    DataTypeTypes["CONSUMPTION"] = "CONSUMPTION";
    DataTypeTypes["CONSUMPTION_PULSE"] = "CONSUMPTION_PULSE";
    DataTypeTypes["DELIVERED"] = "DELIVERED";
    DataTypeTypes["ASSET_SIGNAL_STRENGTH"] = "ASSET_SIGNAL_STRENGTH";
    DataTypeTypes["NB_IOT_ECL"] = "NB_IOT_ECL";
    DataTypeTypes["ASSET_BATTERY_ALARM"] = "ASSET_BATTERY_ALARM";
    DataTypeTypes["ASSET_BATTERY_VOLTAGE"] = "ASSET_BATTERY_VOLTAGE";
    DataTypeTypes["ROOM_TEMPERATURE"] = "ROOM_TEMPERATURE";
    DataTypeTypes["TEMPERATURE"] = "TEMPERATURE";
    DataTypeTypes["EXTERNAL_TEMPERATURE"] = "EXTERNAL_TEMPERATURE";
    DataTypeTypes["VOC"] = "VOC";
    DataTypeTypes["CO2"] = "CO2";
    DataTypeTypes["PRESSURE"] = "PRESSURE";
    DataTypeTypes["HUMIDITY"] = "HUMIDITY";
    DataTypeTypes["OCCUPANCY"] = "OCCUPANCY";
    DataTypeTypes["OPEN"] = "OPEN";
    DataTypeTypes["LIGHT"] = "LIGHT";
    DataTypeTypes["ASSET_BATTERY_PERCENT"] = "ASSET_BATTERY_PERCENT";
    DataTypeTypes["WATER_PULSE"] = "WATER_PULSE";
    DataTypeTypes["DELIVERED_WATER"] = "DELIVERED_WATER";
    DataTypeTypes["WATER_PULSE_HOT"] = "WATER_PULSE_HOT";
    DataTypeTypes["DELIVERED_WATER_HOT"] = "DELIVERED_WATER_HOT";
    DataTypeTypes["GAS_PULSE"] = "GAS_PULSE";
    DataTypeTypes["DELIVERED_GAS"] = "DELIVERED_GAS";
    DataTypeTypes["GENERATED"] = "GENERATED";
    DataTypeTypes["CURRENT"] = "CURRENT";
    DataTypeTypes["EXPORTED_DELIVERED"] = "EXPORTED_DELIVERED";
    DataTypeTypes["EXPORTED_CONSUMPTION"] = "EXPORTED_CONSUMPTION";
    DataTypeTypes["GENERATED_DELIVERED"] = "GENERATED_DELIVERED";
    DataTypeTypes["GENERATED_CONSUMPTION"] = "GENERATED_CONSUMPTION";
    DataTypeTypes["GAS_ENERGY_DELIVERED"] = "GAS_ENERGY_DELIVERED";
    DataTypeTypes["GAS_ENERGY_CONSUMPTION"] = "GAS_ENERGY_CONSUMPTION";
    DataTypeTypes["GATEWAY_MOBILE_STRENGTH"] = "GATEWAY_MOBILE_STRENGTH";
    DataTypeTypes["GATEWAY_WIFI_STRENGTH"] = "GATEWAY_WIFI_STRENGTH";
    DataTypeTypes["DELIVERED_SPEND"] = "DELIVERED_SPEND";
    DataTypeTypes["DELIVERED_GAS_SPEND"] = "DELIVERED_GAS_SPEND";
    DataTypeTypes["DELIVERED_WATER_SPEND"] = "DELIVERED_WATER_SPEND";
    DataTypeTypes["DELIVERED_WATER_HOT_SPEND"] = "DELIVERED_WATER_HOT_SPEND";
    DataTypeTypes["GAS_ENERGY_DELIVERED_SPEND"] = "GAS_ENERGY_DELIVERED_SPEND";
    DataTypeTypes["DELIVERED_CARBON"] = "DELIVERED_CARBON";
    DataTypeTypes["DELIVERED_WATER_CARBON"] = "DELIVERED_WATER_CARBON";
    DataTypeTypes["DELIVERED_WATER_HOT_CARBON"] = "DELIVERED_WATER_HOT_CARBON";
    DataTypeTypes["DELIVERED_GAS_CARBON"] = "DELIVERED_GAS_CARBON";
    DataTypeTypes["GAS_ENERGY_DELIVERED_CARBON"] = "GAS_ENERGY_DELIVERED_CARBON";
    DataTypeTypes["EXPORTED_DELIVERED_SPEND"] = "EXPORTED_DELIVERED_SPEND";
    DataTypeTypes["GENERATED_DELIVERED_SPEND"] = "GENERATED_DELIVERED_SPEND";
    DataTypeTypes["EXPORTED_DELIVERED_CARBON"] = "EXPORTED_DELIVERED_CARBON";
    DataTypeTypes["GENERATED_DELIVERED_CARBON"] = "GENERATED_DELIVERED_CARBON";
})(DataTypeTypes || (exports.DataTypeTypes = DataTypeTypes = {}));
let DataTypes = [
    {
        type: DataTypeTypes.DELIVERED,
        label: 'Usage',
        dataTypeSibling: DataTypeTypes.CONSUMPTION,
        graphType: 'bar',
        unit: Units.find(u => u.id === 'WATT_HOUR').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'WATT_HOUR'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY),
        influxLoadTypes: {
            [LoadTypes.CONSUMPTION_ONLY]: influxLoadTypes.DELIVERED,
            [LoadTypes.GENERATION_ONLY]: null,
            [LoadTypes.CONSUMPTION_EXPORT]: influxLoadTypes.DELIVERED
        }
    },
    {
        type: DataTypeTypes.DELIVERED_WATER_HOT,
        dataTypeSibling: DataTypeTypes.WATER_PULSE_HOT,
        label: 'Usage',
        graphType: 'bar',
        unit: Units.find(u => u.id === 'CUBIC_METER').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CUBIC_METER'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.HOT_WATER).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.HOT_WATER)
    },
    {
        type: DataTypeTypes.DELIVERED_GAS,
        dataTypeSibling: DataTypeTypes.GAS_PULSE,
        label: 'Volume',
        unit: Units.find(u => u.id === 'CUBIC_METER').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CUBIC_METER'),
        graphType: 'bar',
        category: DataCategories.find(u => u.id === DataCategoryTypes.GAS).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GAS)
    },
    {
        type: DataTypeTypes.GAS_PULSE,
        dataTypeSibling: DataTypeTypes.DELIVERED_GAS,
        label: 'Rate',
        graphType: 'line',
        unit: Units.find(u => u.id === 'CUBIC_METER').metaData.symbol + '/' + Units.find(u => u.id === 'TIME').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CUBIC_METER'),
        applyRate: true,
        rateUnitDetails: Units.find(u => u.id === 'TIME'),
        defaultRateMultiple: 3600,
        category: DataCategories.find(u => u.id === DataCategoryTypes.GAS).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GAS),
        summary: true
    },
    {
        type: DataTypeTypes.GAS_ENERGY_DELIVERED,
        dataTypeSibling: DataTypeTypes.GAS_ENERGY_CONSUMPTION,
        label: 'Energy',
        unit: Units.find(u => u.id === 'WATT_HOUR').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'WATT_HOUR'),
        graphType: 'bar',
        category: DataCategories.find(u => u.id === DataCategoryTypes.GAS).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GAS)
    },
    {
        type: DataTypeTypes.GAS_ENERGY_CONSUMPTION,
        dataTypeSibling: DataTypeTypes.GAS_ENERGY_DELIVERED,
        label: 'Rate',
        graphType: 'line',
        unit: Units.find(u => u.id === 'WATT').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'WATT'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.GAS).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GAS),
        summary: true
    },
    {
        type: DataTypeTypes.GENERATED,
        dataTypeSibling: DataTypeTypes.GENERATED_CONSUMPTION,
        desiredDirection: 'up',
        label: 'Total Generated',
        graphType: 'bar',
        unit: Units.find(u => u.id === 'WATT_HOUR').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'WATT_HOUR'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.GENERATION).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GENERATION)
    },
    {
        type: DataTypeTypes.CONSUMPTION,
        label: 'Rate',
        dataTypeSibling: DataTypeTypes.DELIVERED,
        graphType: 'line',
        unit: Units.find(u => u.id === 'WATT').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'WATT'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY),
        summary: true,
        influxLoadTypes: {
            [LoadTypes.CONSUMPTION_ONLY]: influxLoadTypes.CONSUMPTION,
            [LoadTypes.GENERATION_ONLY]: null,
            [LoadTypes.CONSUMPTION_EXPORT]: influxLoadTypes.CONSUMPTION
        }
    },
    {
        type: DataTypeTypes.CONSUMPTION_PULSE,
        dataTypeSibling: DataTypeTypes.DELIVERED,
        label: 'Pulse Rate',
        graphType: 'line',
        unit: Units.find(u => u.id === 'WATT').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'WATT'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY),
        summary: true
    },
    {
        type: DataTypeTypes.ASSET_SIGNAL_STRENGTH,
        label: 'Strength',
        graphType: 'line',
        unit: Units.find(u => u.id === 'DECIBEL').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'DECIBEL'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.SIGNAL).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.SIGNAL)
    },
    {
        type: DataTypeTypes.NB_IOT_ECL,
        unit: '',
        label: 'NB-IoT Comms Level',
        graphType: 'line',
        category: DataCategories.find(u => u.id === DataCategoryTypes.SIGNAL).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.SIGNAL)
    },
    {
        type: DataTypeTypes.ASSET_BATTERY_ALARM,
        label: 'Alarm',
        graphType: 'line',
        unit: '',
        category: DataCategories.find(u => u.id === DataCategoryTypes.BATTERY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.BATTERY)
    },
    {
        type: DataTypeTypes.ASSET_BATTERY_VOLTAGE,
        label: 'Voltage',
        graphType: 'line',
        unit: Units.find(u => u.id === 'VOLT').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'VOLT'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.BATTERY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.BATTERY)
    },
    {
        type: DataTypeTypes.ROOM_TEMPERATURE,
        label: 'Room Temperature [Hive Thermostat]',
        graphType: 'line',
        unit: Units.find(u => u.id === 'TEMPERATURE').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'TEMPERATURE'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.TEMPERATURE).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.TEMPERATURE),
        summary: true,
        allowNegative: true
    },
    {
        type: DataTypeTypes.TEMPERATURE,
        nonAdditive: true,
        graphType: 'line',
        label: 'Temperature',
        factor: 0.01,
        unit: Units.find(u => u.id === 'TEMPERATURE').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'TEMPERATURE'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.TEMPERATURE).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.TEMPERATURE),
        summary: true,
        allowNegative: true
    },
    {
        type: DataTypeTypes.EXTERNAL_TEMPERATURE,
        label: 'External Temperature',
        graphType: 'line',
        unit: Units.find(u => u.id === 'TEMPERATURE').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'TEMPERATURE'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.TEMPERATURE).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.TEMPERATURE),
        summary: false,
        isFromLocation: true,
        allowNegative: true
    },
    {
        type: DataTypeTypes.VOC,
        graphType: 'line',
        label: 'Air Quality',
        factor: 0.01,
        unit: Units.find(u => u.id === 'INDOOR_AIR_QUALITY').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'INDOOR_AIR_QUALITY'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.INDOOR_AIR_QUALITY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.INDOOR_AIR_QUALITY),
        summary: true
    },
    {
        type: DataTypeTypes.PRESSURE,
        graphType: 'line',
        label: 'Pressure',
        factor: 0.1,
        unit: Units.find(u => u.id === 'PRESSURE').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'PRESSURE'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.PRESSURE).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.PRESSURE),
        summary: true
    },
    {
        type: DataTypeTypes.CO2,
        graphType: 'line',
        label: 'Carbon Dioxide',
        factor: 1,
        unit: Units.find(u => u.id === 'CO2').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CO2'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.CO2).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.CO2),
        summary: true
    },
    {
        type: DataTypeTypes.HUMIDITY,
        nonAdditive: true,
        label: 'Humidity',
        graphType: 'line',
        unit: Units.find(u => u.id === 'PERCENTAGE').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'PERCENTAGE'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.HUMIDITY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.HUMIDITY),
        summary: true
    },
    {
        type: DataTypeTypes.OCCUPANCY,
        label: 'Occupancy',
        graphType: 'line',
        discrete: true,
        unit: Units.find(u => u.id === 'OCCUPANCY').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'OCCUPANCY'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.OCCUPANCY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.OCCUPANCY),
        summary: true
    },
    {
        type: DataTypeTypes.OPEN,
        label: 'Open/Close',
        discrete: true,
        unit: Units.find(u => u.id === 'OPEN_CLOSED').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'OPEN_CLOSED'),
        summary: true,
        graphType: 'line',
        category: DataCategories.find(u => u.id === DataCategoryTypes.OPEN_CLOSED).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.OPEN_CLOSED)
    },
    {
        type: DataTypeTypes.LIGHT,
        label: 'Light',
        graphType: 'line',
        unit: Units.find(u => u.id === 'LUMINANCE').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'LUMINANCE'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.LIGHT).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.LIGHT),
        modifier: ((v) => v !== null ? Math.round(Math.pow(10, ((v - 1) / 10000)) * 10) / 10 : null),
        summary: true
    },
    {
        type: DataTypeTypes.ASSET_BATTERY_PERCENT,
        label: 'Storage Percent',
        graphType: 'line',
        unit: Units.find(u => u.id === 'PERCENTAGE').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'PERCENTAGE'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.BATTERY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.BATTERY),
        summary: true
    },
    {
        type: DataTypeTypes.WATER_PULSE,
        dataTypeSibling: DataTypeTypes.DELIVERED_WATER,
        label: 'Rate',
        graphType: 'line',
        unit: Units.find(u => u.id === 'CUBIC_METER').metaData.symbol + '/' + Units.find(u => u.id === 'TIME').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CUBIC_METER'),
        applyRate: true,
        rateUnitDetails: Units.find(u => u.id === 'TIME'),
        defaultRateMultiple: 3600,
        category: DataCategories.find(u => u.id === DataCategoryTypes.WATER).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.WATER),
        summary: true
    },
    {
        type: DataTypeTypes.DELIVERED_WATER,
        dataTypeSibling: DataTypeTypes.WATER_PULSE,
        label: 'Usage',
        graphType: 'bar',
        unit: Units.find(u => u.id === 'CUBIC_METER').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CUBIC_METER'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.WATER).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.WATER)
    },
    {
        type: DataTypeTypes.WATER_PULSE_HOT,
        dataTypeSibling: DataTypeTypes.DELIVERED_WATER_HOT,
        label: 'Rate',
        graphType: 'line',
        unit: Units.find(u => u.id === 'CUBIC_METER').metaData.symbol + '/' + Units.find(u => u.id === 'TIME').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CUBIC_METER'),
        applyRate: true,
        rateUnitDetails: Units.find(u => u.id === 'TIME'),
        defaultRateMultiple: 3600,
        category: DataCategories.find(u => u.id === DataCategoryTypes.HOT_WATER).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.HOT_WATER),
        summary: true
    },
    {
        type: DataTypeTypes.CURRENT,
        label: 'Current',
        graphType: 'line',
        factor: 0.001,
        unit: Units.find(u => u.id === 'AMPERE').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'AMPERE'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY)
    },
    {
        type: DataTypeTypes.EXPORTED_DELIVERED,
        dataTypeSibling: DataTypeTypes.EXPORTED_CONSUMPTION,
        label: 'Usage',
        graphType: 'bar',
        unit: Units.find(u => u.id === 'WATT_HOUR').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'WATT_HOUR'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.EXPORTED).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.EXPORTED),
        influxLoadTypes: {
            [LoadTypes.CONSUMPTION_ONLY]: null,
            [LoadTypes.GENERATION_ONLY]: null,
            [LoadTypes.CONSUMPTION_EXPORT]: influxLoadTypes.GENERATED
        },
        unsafeToRetrieveWithoutLoadType: true
    },
    {
        type: DataTypeTypes.GENERATED_DELIVERED,
        dataTypeSibling: DataTypeTypes.GENERATED_CONSUMPTION,
        desiredDirection: 'up',
        label: 'Generated',
        graphType: 'bar',
        unit: Units.find(u => u.id === 'WATT_HOUR').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'WATT_HOUR'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.GENERATION).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GENERATION),
        influxLoadTypes: {
            [LoadTypes.CONSUMPTION_ONLY]: null,
            [LoadTypes.GENERATION_ONLY]: influxLoadTypes.DELIVERED,
            [LoadTypes.CONSUMPTION_EXPORT]: influxLoadTypes.GENERATED
        },
        unsafeToRetrieveWithoutLoadType: true
    },
    {
        type: DataTypeTypes.GENERATED_CONSUMPTION,
        dataTypeSibling: DataTypeTypes.GENERATED_DELIVERED,
        desiredDirection: 'up',
        label: 'Rate',
        graphType: 'line',
        unit: Units.find(u => u.id === 'WATT').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'WATT'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.GENERATION).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GENERATION),
        summary: true,
        influxLoadTypes: {
            [LoadTypes.CONSUMPTION_ONLY]: null,
            [LoadTypes.GENERATION_ONLY]: influxLoadTypes.CONSUMPTION,
            [LoadTypes.CONSUMPTION_EXPORT]: null
        },
        influxLegacyLoadTypes: {
            [LoadTypes.GENERATION_ONLY]: influxLoadTypes.DELIVERED
        },
        unsafeToRetrieveWithoutLoadType: true
    },
    {
        type: DataTypeTypes.EXPORTED_CONSUMPTION,
        dataTypeSibling: DataTypeTypes.EXPORTED_DELIVERED,
        desiredDirection: 'up',
        label: 'Rate',
        graphType: 'line',
        unit: Units.find(u => u.id === 'WATT').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'WATT'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.EXPORTED).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.EXPORTED),
        summary: true,
        influxLoadTypes: {
            [LoadTypes.CONSUMPTION_ONLY]: null,
            [LoadTypes.GENERATION_ONLY]: null,
            [LoadTypes.CONSUMPTION_EXPORT]: influxLoadTypes.CONSUMPTION
        },
        unsafeToRetrieveWithoutLoadType: true
    },
    {
        type: DataTypeTypes.GATEWAY_MOBILE_STRENGTH,
        desiredDirection: 'up',
        label: 'Mobile Signal Strength',
        graphType: 'line',
        unit: Units.find(u => u.id === 'DECIBEL').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'DECIBEL'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.SIGNAL).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.SIGNAL)
    },
    {
        type: DataTypeTypes.GATEWAY_WIFI_STRENGTH,
        desiredDirection: 'up',
        label: 'WiFi Signal Strength',
        graphType: 'line',
        unit: Units.find(u => u.id === 'DECIBEL').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'DECIBEL'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.SIGNAL).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.SIGNAL)
    },
    {
        type: DataTypeTypes.DELIVERED_SPEND,
        label: 'Spend',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'CURRENCY').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CURRENCY'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.DELIVERED,
        tariffType: 'spend',
        tariffable: true
    },
    {
        type: DataTypeTypes.EXPORTED_DELIVERED_SPEND,
        label: 'Spend',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'CURRENCY').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CURRENCY'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.EXPORTED).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.EXPORTED),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.EXPORTED_DELIVERED,
        tariffType: 'spend',
        tariffable: true
    },
    {
        type: DataTypeTypes.GENERATED_DELIVERED_SPEND,
        label: 'Spend',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'CURRENCY').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CURRENCY'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.GENERATION).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GENERATION),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.GENERATED_DELIVERED,
        tariffType: 'spend',
        tariffable: true
    },
    {
        type: DataTypeTypes.DELIVERED_WATER_SPEND,
        label: 'Spend',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'CURRENCY').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CURRENCY'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.WATER).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.WATER),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.DELIVERED_WATER,
        tariffType: 'spend',
        tariffable: true
    },
    {
        type: DataTypeTypes.DELIVERED_WATER_HOT_SPEND,
        label: 'Spend',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'CURRENCY').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CURRENCY'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.HOT_WATER).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.HOT_WATER),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.DELIVERED_WATER_HOT,
        tariffType: 'spend',
        tariffable: true
    },
    {
        type: DataTypeTypes.DELIVERED_GAS_SPEND,
        label: 'Spend',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'CURRENCY').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CURRENCY'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.GAS).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GAS),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.DELIVERED_GAS,
        tariffType: 'spend',
        tariffable: true
    },
    {
        type: DataTypeTypes.GAS_ENERGY_DELIVERED_SPEND,
        label: 'Spend',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'CURRENCY').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'CURRENCY'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.GAS).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GAS),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.GAS_ENERGY_DELIVERED,
        tariffType: 'spend',
        tariffable: true
    },
    {
        type: DataTypeTypes.DELIVERED_CARBON,
        label: 'Carbon',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'GRAM').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'GRAM'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.ELECTRICITY),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.DELIVERED,
        tariffType: 'carbon',
        tariffable: true
    },
    {
        type: DataTypeTypes.EXPORTED_DELIVERED_CARBON,
        label: 'Carbon',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'GRAM').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'GRAM'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.EXPORTED).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.EXPORTED),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.EXPORTED_DELIVERED,
        tariffType: 'carbon',
        tariffable: true
    },
    {
        type: DataTypeTypes.GENERATED_DELIVERED_CARBON,
        label: 'Carbon',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'GRAM').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'GRAM'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.GENERATION).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GENERATION),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.GENERATED_DELIVERED,
        tariffType: 'carbon',
        tariffable: true
    },
    {
        type: DataTypeTypes.DELIVERED_WATER_CARBON,
        label: 'Carbon',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'GRAM').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'GRAM'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.WATER).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.WATER),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.DELIVERED_WATER,
        tariffType: 'carbon',
        tariffable: true
    },
    {
        type: DataTypeTypes.DELIVERED_WATER_HOT_CARBON,
        label: 'Carbon',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'GRAM').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'GRAM'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.HOT_WATER).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.HOT_WATER),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.DELIVERED_WATER_HOT,
        tariffType: 'carbon',
        tariffable: true
    },
    {
        type: DataTypeTypes.DELIVERED_GAS_CARBON,
        label: 'Carbon',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'GRAM').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'GRAM'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.GAS).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GAS),
        downloadOnly: true,
        tariffComparatorDataType: DataTypeTypes.DELIVERED_GAS,
        tariffType: 'carbon',
        tariffable: true
    },
    {
        type: DataTypeTypes.GAS_ENERGY_DELIVERED_CARBON,
        label: 'Carbon',
        graphType: 'bar',
        factor: 1,
        unit: Units.find(u => u.id === 'GRAM').metaData.symbol,
        unitDetails: Units.find(u => u.id === 'GRAM'),
        category: DataCategories.find(u => u.id === DataCategoryTypes.GAS).name,
        categoryDetails: DataCategories.find(u => u.id === DataCategoryTypes.GAS),
        downloadOnly: true,
        tariffable: true,
        tariffType: 'carbon',
        tariffComparatorDataType: DataTypeTypes.GAS_ENERGY_DELIVERED
    }
];
exports.DataTypes = DataTypes;
// Changes to make custom data types work
exports.DataTypes = DataTypes = DataTypes.map((dt) => {
    dt.id = dt.type;
    dt.name = dt.label;
    dt.dataType = dt.graphType === 'line' ? 'instantaneous' : 'aggregate';
    if (dt.unitDetails) {
        dt.unitId = dt.unitDetails.id;
    }
    dt.categoryId = dt.categoryDetails.id;
    if (dt.applyRate && dt.rateUnitDetails) {
        dt.rateUnitId = dt.rateUnitDetails.id;
    }
    return dt;
});
const Options = [
    {
        label: 'On',
        state: 'ON',
        default: true,
        color: '#49c570'
    },
    {
        label: 'Off',
        state: 'OFF',
        color: '#ff5c5c'
    },
    {
        label: 'Following Rule',
        state: 'AUTOMATED',
        icon: 'FaCalculator',
        nonSelectable: true
    },
    {
        state: 'BOOST',
        nonSelectable: true
    },
    {
        state: 'SNOOZE',
        nonSelectable: true
    },
    {
        label: 'None',
        state: 'NONE',
        color: '#cccccc'
    },
    {
        label: 'Monitoring',
        state: 'MONITORING',
        color: '#03a9f4',
        nonSelectable: true
    }
];
const StateOptions = [
    {
        type: 'default',
        label: 'Default GridDuck Asset',
        resolution: 5 * 60 * 1000,
        connection: Connections.ZIGBEE,
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH)
        ],
        options: [
            Options.find(o => o.state === 'ON'),
            Options.find(o => o.state === 'OFF'),
            Options.find(o => o.state === 'AUTOMATED'),
            Options.find(o => o.state === 'BOOST'),
            Options.find(o => o.state === 'SNOOZE'),
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'INITIALISING_SKU',
        label: 'Device Initialising...',
        resolution: 5 * 60 * 1000,
        connection: Connections.ZIGBEE,
        dataTypes: [],
        options: []
    },
    {
        type: 'PLU-UK',
        connection: Connections.ZIGBEE,
        label: 'UK Plug',
        resolution: 5 * 60 * 1000,
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH)
        ],
        options: [
            Options.find(o => o.state === 'ON'),
            Options.find(o => o.state === 'OFF'),
            Options.find(o => o.state === 'AUTOMATED'),
            Options.find(o => o.state === 'BOOST'),
            Options.find(o => o.state === 'SNOOZE'),
            Options.find(o => o.state === 'MONITORING')
        ]
    },
    {
        type: 'SOCKET-DOUBLE-OWON',
        connection: Connections.ZIGBEE,
        label: 'UK Double Socket',
        resolution: 5 * 60 * 1000,
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH)
        ],
        options: [
            Options.find(o => o.state === 'ON'),
            Options.find(o => o.state === 'OFF'),
            Options.find(o => o.state === 'AUTOMATED'),
            Options.find(o => o.state === 'BOOST'),
            Options.find(o => o.state === 'SNOOZE'),
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'INL-SWI',
        connection: Connections.ZIGBEE,
        label: 'Inline Switch',
        resolution: 5 * 60 * 1000,
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH)
        ],
        options: [
            Options.find(o => o.state === 'ON'),
            Options.find(o => o.state === 'OFF'),
            Options.find(o => o.state === 'AUTOMATED'),
            Options.find(o => o.state === 'BOOST'),
            Options.find(o => o.state === 'SNOOZE'),
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'POWERVAULT',
        connection: Connections.API,
        resolution: 5 * 60 * 1000,
        label: 'Powervault Secondary Asset',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON)
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ],
        allowNegative: true
    },
    {
        type: 'CLI-SEN-POW',
        resolution: 5 * 60 * 1000,
        connection: Connections.ZIGBEE,
        label: 'Powered Clamp Meter',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON)
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'SEN-POW-OWON',
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: 'Powered Clamp Meter',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.CURRENT),
            // DataTypes.find(t => t.type === DataTypeTypes.GENERATED),
            DataTypes.find(t => t.type === DataTypeTypes.EXPORTED_CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.EXPORTED_DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.GENERATED_DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.GENERATED_CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.EXPORTED_DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.EXPORTED_DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.GENERATED_DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.GENERATED_DELIVERED_SPEND)
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ],
        allowNegative: true
    },
    {
        type: 'SEN-POW-PRE',
        connection: Connections.PRESSAC,
        resolution: 5 * 60 * 1000,
        label: 'Self Powered CT',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.CURRENT),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH)
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'POWER_RADAR',
        connection: Connections.POWER_RADAR,
        resolution: 60 * 60 * 1000,
        noDisconnection: true,
        label: 'Power Radar',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.CURRENT)
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'SOLAR_EDGE',
        connection: Connections.SOLAR_EDGE,
        resolution: 15 * 60 * 1000,
        noDisconnection: true,
        label: 'Solar Edge',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.GENERATED_CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.GENERATED_DELIVERED),
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ],
    },
    {
        type: 'POWER_RADAR_METER_GAS',
        connection: Connections.POWER_RADAR,
        resolution: 60 * 60 * 1000,
        noDisconnection: true,
        label: 'Power Radar Gas Meter',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.GAS_ENERGY_CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.GAS_ENERGY_DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.GAS_ENERGY_DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.GAS_ENERGY_DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.GAS_PULSE),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS_CARBON)
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'POWER_RADAR_METER_WATER',
        connection: Connections.POWER_RADAR,
        resolution: 60 * 60 * 1000,
        noDisconnection: true,
        label: 'Power Radar Water Meter',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.WATER_PULSE),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON)
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'SEN-POW-OWON-OLD',
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: 'Powered Clamp Meter',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.CURRENT),
            DataTypes.find(t => t.type === DataTypeTypes.EXPORTED_CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.EXPORTED_DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.GENERATED_DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.GENERATED_CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.EXPORTED_DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.EXPORTED_DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.GENERATED_DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.GENERATED_DELIVERED_SPEND)
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ],
        allowNegative: true
    },
    {
        type: 'SEN-POW',
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: 'Battery Clamp Meter',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_VOLTAGE)
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'MET-REA',
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: 'Meter Reader',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'SEN-TEM-PROBE',
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: 'Temperature Probe',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.TEMPERATURE),
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'SEN-TEM-HUM',
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: 'Temperature/Humidity Sensor',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.TEMPERATURE),
            DataTypes.find(t => t.type === DataTypeTypes.HUMIDITY),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: "SEN-LIGHT-MOTION",
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: 'Light/Occupancy Sensor',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.LIGHT),
            DataTypes.find(t => t.type === DataTypeTypes.OCCUPANCY),
            DataTypes.find(t => t.type === DataTypeTypes.TEMPERATURE),
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: "VIRTUAL-PARENT",
        connection: Connections.VIRTUAL,
        virtual: true,
        noDisconnection: true,
        label: 'Virtual Parent',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON)
        ],
        options: [
            Options.find(o => o.state === 'NONE')
        ]
    },
    {
        type: 'POWERVAULT_BATTERY',
        connection: Connections.API,
        resolution: 5 * 60 * 1000,
        label: 'Powervault Battery',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_PERCENT)
        ],
        options: [
            Options.find(o => o.state === 'MONITORING'),
            {
                label: 'Only Charge',
                state: 'ONLY_CHARGE',
                color: '#4dc7d2'
            },
            {
                label: 'Force Charge',
                state: 'FORCE_CHARGE',
                color: '#4d5fd2'
            },
            {
                label: 'Only Discharge',
                state: 'ONLY_DISCHARGE',
                color: '#ff87a7'
            },
            {
                label: 'Force Discharge',
                state: 'FORCE_DISCHARGE',
                color: '#fa2488'
            },
            {
                label: 'Normal',
                default: true,
                state: 'NORMAL',
                color: '#49c570'
            },
            {
                label: 'Disable',
                state: 'DISABLE',
                color: '#ff5c5c'
            }
        ],
        allowNegative: true
    },
    {
        type: 'REL-DIN',
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: '16A DIN Relay',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH)
        ],
        options: [
            {
                label: 'On',
                state: 'ON',
                default: true,
                color: '#49c570'
            },
            {
                label: 'Off',
                state: 'OFF',
                color: '#ff5c5c'
            },
            {
                label: 'Following Rule',
                state: 'AUTOMATED',
                icon: 'FaCalculator',
                nonSelectable: true
            },
            {
                state: 'BOOST',
                nonSelectable: true
            },
            {
                state: 'SNOOZE',
                nonSelectable: true
            },
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'REL-DIN-30A',
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: '30A DIN Relay',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH)
        ],
        options: [
            {
                label: 'On',
                state: 'ON',
                default: true,
                color: '#49c570'
            },
            {
                label: 'Off',
                state: 'OFF',
                color: '#ff5c5c'
            },
            {
                label: 'Following Rule',
                state: 'AUTOMATED',
                icon: 'FaCalculator',
                nonSelectable: true
            },
            {
                state: 'BOOST',
                nonSelectable: true
            },
            {
                state: 'SNOOZE',
                nonSelectable: true
            },
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'REL-DIN-32A-OWON',
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: '32A DIN Relay',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH)
        ],
        options: [
            {
                label: 'On',
                state: 'ON',
                default: true,
                color: '#49c570'
            },
            {
                label: 'Off',
                state: 'OFF',
                color: '#ff5c5c'
            },
            {
                label: 'Following Rule',
                state: 'AUTOMATED',
                icon: 'FaCalculator',
                nonSelectable: true
            },
            {
                state: 'BOOST',
                nonSelectable: true
            },
            {
                state: 'SNOOZE',
                nonSelectable: true
            },
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'MODBUS',
        connection: Connections.MODBUS_RTU,
        label: 'Modbus Device',
        resolution: 5 * 60 * 1000,
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON)
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'EMLITE',
        connection: Connections.EMLITE,
        label: 'Emlite Meter',
        resolution: 60 * 60 * 1000,
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON)
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'EA_GAS',
        connection: Connections.API,
        resolution: 5 * 60 * 1000,
        label: 'EnergyAsset Gas Data',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS_CARBON)
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'BLE_PULSE_DELIVERED',
        connection: Connections.BLUETOOTH,
        resolution: 5 * 60 * 1000,
        label: 'Pulse Counter (Electricity)',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'BLE_PULSE_DELIVERED_WATER',
        connection: Connections.BLUETOOTH,
        resolution: 5 * 60 * 1000,
        label: 'Pulse Counter (Water)',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.WATER_PULSE),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_CARBON)
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'BLE_PULSE_DELIVERED_WATER_HOT',
        connection: Connections.BLUETOOTH,
        resolution: 5 * 60 * 1000,
        label: 'Pulse Counter (Hot Water)',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.WATER_PULSE_HOT),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_HOT),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_HOT_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_HOT_CARBON)
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'BLE_PULSE_DELIVERED_GAS',
        connection: Connections.BLUETOOTH,
        resolution: 5 * 60 * 1000,
        label: 'Pulse Counter (Gas)',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.GAS_PULSE),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS_CARBON)
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'TRV-DANFOSS',
        connection: Connections.ZIGBEE,
        resolution: 5 * 60 * 1000,
        label: 'Radiator TRV',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.TEMPERATURE),
        ],
        options: [
            Options.find(o => o.state === 'ON'),
            Options.find(o => o.state === 'OFF'),
            Options.find(o => o.state === 'AUTOMATED'),
            Options.find(o => o.state === 'BOOST'),
            Options.find(o => o.state === 'SNOOZE'),
            Options.find(o => o.state === 'MONITORING'),
        ]
    },
    {
        type: 'NB-IOT-PULSE_DELIVERED',
        connection: Connections.NB_IOT,
        resolution: 3 * 60 * 60 * 1000,
        label: 'NB-IoT Pulse Counter (Electricity)',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.CONSUMPTION),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_CARBON),
            DataTypes.find(t => t.type === DataTypeTypes.NB_IOT_ECL),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'NB-IOT-PULSE_DELIVERED_WATER',
        connection: Connections.NB_IOT,
        resolution: 3 * 60 * 60 * 1000,
        label: 'NB-IoT Pulse Counter (Water)',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.WATER_PULSE),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.NB_IOT_ECL),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_CARBON)
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'NB-IOT-PULSE_DELIVERED_WATER_HOT',
        connection: Connections.NB_IOT,
        resolution: 3 * 60 * 60 * 1000,
        label: 'NB-IoT Pulse Counter (Hot Water)',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.WATER_PULSE_HOT),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_HOT),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_HOT_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.NB_IOT_ECL),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_WATER_HOT_CARBON)
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'NB-IOT-PULSE_DELIVERED_GAS',
        connection: Connections.NB_IOT,
        resolution: 3 * 60 * 60 * 1000,
        label: 'NB-IoT Pulse Counter (Gas)',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.GAS_PULSE),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS),
            DataTypes.find(t => t.type === DataTypeTypes.NB_IOT_ECL),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS_SPEND),
            DataTypes.find(t => t.type === DataTypeTypes.DELIVERED_GAS_CARBON)
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'BLE_VOC',
        connection: Connections.BLUETOOTH,
        resolution: 5 * 60 * 1000,
        label: 'Air Quality Sensor',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.TEMPERATURE),
            DataTypes.find(t => t.type === DataTypeTypes.HUMIDITY),
            DataTypes.find(t => t.type === DataTypeTypes.VOC),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'BLE_TEMP',
        connection: Connections.BLUETOOTH,
        resolution: 5 * 60 * 1000,
        label: 'Temperature/Humidity Sensor',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.TEMPERATURE),
            DataTypes.find(t => t.type === DataTypeTypes.HUMIDITY),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'NB-IOT-TEMP',
        connection: Connections.NB_IOT,
        resolution: 3 * 60 * 60 * 1000,
        label: 'NB-IoT Temperature Sensor',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.TEMPERATURE),
            DataTypes.find(t => t.type === DataTypeTypes.HUMIDITY),
            DataTypes.find(t => t.type === DataTypeTypes.NB_IOT_ECL),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'NB-IOT-VOC',
        connection: Connections.NB_IOT,
        resolution: 3 * 60 * 60 * 1000,
        label: 'NB-IoT VOC Sensor',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.TEMPERATURE),
            DataTypes.find(t => t.type === DataTypeTypes.VOC),
            DataTypes.find(t => t.type === DataTypeTypes.HUMIDITY),
            DataTypes.find(t => t.type === DataTypeTypes.NB_IOT_ECL),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'NB-IOT-CO2',
        connection: Connections.NB_IOT,
        resolution: 3 * 60 * 60 * 1000,
        label: 'NB-IoT CO2 Sensor',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.TEMPERATURE),
            DataTypes.find(t => t.type === DataTypeTypes.HUMIDITY),
            DataTypes.find(t => t.type === DataTypeTypes.CO2),
            DataTypes.find(t => t.type === DataTypeTypes.PRESSURE),
            DataTypes.find(t => t.type === DataTypeTypes.NB_IOT_ECL),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
    {
        type: 'BLE_OPEN_CLOSE',
        connection: Connections.BLUETOOTH,
        resolution: 5 * 60 * 1000,
        label: 'Open/Close Sensor',
        dataTypes: [
            DataTypes.find(t => t.type === DataTypeTypes.OPEN),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_SIGNAL_STRENGTH),
            DataTypes.find(t => t.type === DataTypeTypes.ASSET_BATTERY_ALARM),
        ],
        options: [
            {
                label: 'Monitoring',
                state: 'MONITORING',
                color: '#03a9f4',
                nonSelectable: true
            }
        ]
    },
];
exports.StateOptions = StateOptions;
const GatewayOptions = [
    {
        type: 'DEVELCO-HUB',
        resolution: 10 * 60 * 1000 //10 mins
    },
    {
        type: 'ACRIOS-HUB',
        resolution: 60 * 60 * 1000 // 1 hour
    }
];
exports.GatewayOptions = GatewayOptions;
let DeviceOptions = StateOptions;
exports.DeviceOptions = DeviceOptions;
