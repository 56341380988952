import React from "react";
import {
    CardBody,
    Card,
    GdRadio,
    Button,
    Menu,
    GdAutocomplete,
    Input,
    EditableList,
    Icon,
    Dropdown,
    DateTimeRangePicker, StandardListCell, CardHeader, StateOptions
} from "gd-react";
import GridDuck from 'gridduck';
import moment from "moment";
import history from "../../meta/history";
import './CreateDataDownload.scss'
import GenericLoader from "../../components/GenericLoader";
import {createPortal} from "react-dom";

class CreateDataDownload extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.ddState, ' : this.props.ddState');
        this.state = this.props.ddState ? this.props.ddState : {
            selectionType: 'individual',
            filterLogic: 'OR',
            dataType: 'DELIVERED',
            granularity: 60 * 60,
            customGranularity: 60,
            selectedAssets: [],
            filters: [],
            listSaved: false,
            isEditing: false,
            format: 'DD/MM/yyyy HH'
        };
        console.log(moment.unix().startOf('day').subtract(1, 'week'), ' : moment.unix().startOf(\'day\').subtract(1, \'week\')');
        console.log(this.state.range, ' : range');
        this.filteredAssetList = this.props.filteredAssetList ? this.props.filteredAssetList : [];
        this.selectionChanged = this.selectionChanged.bind(this);
        this.getSelection = this.getSelection.bind(this);
        this.getFilters = this.getFilters.bind(this);
        this.getList = this.getList.bind(this);
        this.filterUpdated = this.filterUpdated.bind(this);
        this.deleteFilter = this.deleteFilter.bind(this);
        this.updateSelectedAssets = this.updateSelectedAssets.bind(this);
        this.getIndividualList = this.getIndividualList.bind(this);
        this.getAvailableIndividualList = this.getAvailableIndividualList.bind(this);
        this.saveIndividualList = this.saveIndividualList.bind(this);
        this.setGranularity = this.setGranularity.bind(this);
        this.setCustomGranularity = this.setCustomGranularity.bind(this);
        this.rangeUpdated = this.rangeUpdated.bind(this);
        this.isValidDate = this.isValidDate.bind(this);
        this.createDataRequest = this.createDataRequest.bind(this);
    }

    createDataRequest() {
        let self = this;
        let dateStart = new moment(this.state.dateRange.start).tz(this.state.selectedAssets[0].siteTimezone);
        let browserStart = new moment(this.state.dateRange.start);
        let offsetStart = 0;
        if (dateStart && browserStart) {
            offsetStart = (dateStart.utcOffset() - browserStart.utcOffset()) * 60 * 1000;
        }
        let dateEnd = new moment(this.state.dateRange.end).tz(this.state.selectedAssets[0].siteTimezone);
        let browserEnd = new moment(this.state.dateRange.end);
        let offsetEnd = 0;
        if (dateEnd && browserEnd) {
            offsetEnd = (dateEnd.utcOffset() - browserEnd.utcOffset()) * 60 * 1000;
        }
        console.log(this.state.dateRange.start, offsetStart,);
        console.log({
            granularity: this.state.granularity !== 'custom' ? this.state.granularity : this.state.customGranularity,
            assetIds: this.state.selectedAssets.map(a => a.id),
            startDate: moment(this.state.dateRange.start - offsetStart).unix(),
            endDate: moment(this.state.dateRange.end - offsetEnd).unix(),
            dataType: this.state.dataType
        });
        return GridDuck.createDataRequest({
            granularity: this.state.granularity !== 'custom' ? this.state.granularity : this.state.customGranularity,
            assetIds: this.state.selectedAssets.map(a => a.id),
            startDate: moment(this.state.dateRange.start - offsetStart).unix(),
            endDate: moment(this.state.dateRange.end - offsetEnd).unix(),
            dataType: this.state.dataType
        }).then(function () {
            console.log('Created Download');
            history.push("/data-download/archive");
            if (self.props.onsubmit) {
                console.log('Close modal');
                self.props.onsubmit()
            }
        });
    }

    isValidDate() {
        return ((this.state.granularity && (this.state.granularity !== 'custom' || this.state.customGranularity)) && (this.state.dateRange));
    }

    setGranularity(e) {
        console.log(e, ' : gran changed');
        let val = e.target.value, format = 'DD/MM/yyyy HH:mm';
        if (val !== 'custom') {
            if (val === 60 * 60) {
                format = 'DD/MM/yyyy HH';
            } else if (val === 86400) {
                format = 'DD/MM/yyyy';
            }
        }

        this.setState({granularity: e.target.value, format: format})
    }

    selectionChanged(e) {
        let self = this;
        this.setState({selectionType: e.target.value, selectedAssets: [], filters: [], isEditing: true}, () => {
            if (this.props.inPage) this.props.onUpdate([], this.state, this.filteredAssetList)
        });
        if (e.target.value === 'individual') {
            this.setState({listSaved: false});
        }
        if (e.target.value === 'all') {
            self.setState({loading: true});
            GridDuck.getAssets({
                getAll: true,
                filters: this.props.onlyAllow
            }).then(function (assetList) {
                self.setState({
                    selectedAssets: assetList.list,
                    loading: false
                }, () => {
                    if (self.props.inPage) self.props.onUpdate(assetList.list, self.state, self.filteredAssetList);
                });
            });
        }
    }

    deleteFilter(filterIndex) {
        let filters = this.state.filters;
        let thisFilter = filters[filterIndex];
        let selectedAssets = this.state.selectedAssets ? this.state.selectedAssets : [];
        selectedAssets = selectedAssets.filter(a => a.siteId !== thisFilter.id);
        filters.splice(filterIndex, 1);
        this.filteredAssetList.splice(filterIndex, 1);
        this.setState({filters: filters, selectedAssets: selectedAssets}, () => {
            if (this.props.inPage) this.props.onUpdate(selectedAssets, this.state, this.filteredAssetList)
        });
        this.updateSelectedAssets();
    }

    filterUpdated(filterIndex, params) {
        let self = this;
        let filters = this.state.filters;
        console.log(filters, ' : filters');
        let FFilters = this.props.onlyAllow ? this.props.onlyAllow : [];
        if (params.item) {
            let item = params.item;
            filters[filterIndex].id = item.id;
            self.setState({filters: filters, loading: true});

            //  //TODO Add in response of only site_ids and group_ids
            // if (this.props.inPage === 'report') {return self.setState({selectedAssets: FFilters.concat([{
            //          field: 'siteId',
            //          value: item.id
            //      }]), loading: false}, ()=> {if (self.props.inPage) self.props.onUpdate(validAssetsSoFar, self.state, self.filteredAssetList)});}

            switch (filters[filterIndex].type) {
                case 'site':
                    GridDuck.getAssets({
                        getAll: true,
                        filters: FFilters.concat([{
                            field: 'siteId',
                            value: item.id
                        }])
                    }).then(function (assetList) {
                        self.filteredAssetList[filterIndex] = assetList.list;
                        self.updateSelectedAssets();
                    });
                    break;
                case 'group':
                    GridDuck.getGroup(
                        {
                            id: item.id
                        })
                        .then(function (group) {
                            return GridDuck.getAssets({
                                filters: FFilters.concat([
                                    {
                                        field: 'id',
                                        value: group.assetIds
                                    }
                                ])
                            }).then(function (assetList) {
                                self.filteredAssetList[filterIndex] = assetList.list;
                                self.updateSelectedAssets();
                            });
                        });
                    break;
                case 'Site Group':
                    GridDuck.getSiteGroup(
                        {
                            id: item.id
                        })
                        .then(function (siteGroup) {
                            let siteAssetsPromises = [];
                            siteGroup.siteIds.forEach((sid) => {
                                siteAssetsPromises.push(GridDuck.getAssets({
                                    filters: FFilters.concat([{
                                        field: 'siteId',
                                        value: sid
                                    }])
                                }));
                            });
                            Promise.all(siteAssetsPromises).then((sats) => {
                                let allAssets = [];
                                sats.forEach((s) => {
                                    allAssets = allAssets.concat(s.list);
                                });
                                self.filteredAssetList[filterIndex] = allAssets;
                                console.log(allAssets, ' : allAssets');
                                self.updateSelectedAssets();
                            });
                        });
                    break;
                default:
                    break;
            }
        }
        if (params.logic) {
            self.setState({filterLogic: params.logic}, self.updateSelectedAssets);
        }
    }

    updateSelectedAssets() {
        let self = this;
        let logic = this.state.filterLogic;
        console.log(this.filteredAssetList);
        if (logic === 'OR') {
            let selectedAssets = [];
            this.filteredAssetList.forEach(function (assetList) {
                assetList.forEach(function (asset) {
                    if (!selectedAssets.find(a => a.id === asset.id)) {
                        selectedAssets.push(asset);
                    }
                })
            });
            this.setState({selectedAssets: selectedAssets, loading: false}, () => {
                if (this.props.inPage) this.props.onUpdate(selectedAssets, this.state, this.filteredAssetList)
            });
        } else if (logic === 'AND') {
            let validAssetsSoFar = [];
            if (this.filteredAssetList.length > 0) {
                validAssetsSoFar = this.filteredAssetList[0].slice();
                console.log("valid so far", validAssetsSoFar);
                this.filteredAssetList.forEach(function (assetList) {
                    let toRemove = [];
                    validAssetsSoFar.forEach(function (validAssetSoFar) {
                        if (!assetList.find(a => a.id === validAssetSoFar.id)) {
                            toRemove.push(validAssetSoFar.id);
                        }
                    })
                    console.log("to remove", toRemove);
                    toRemove.forEach(function (assetIdToRemove) {
                        validAssetsSoFar.splice(validAssetsSoFar.indexOf(validAssetsSoFar.find(a => a.id === assetIdToRemove)), 1);
                    })
                });
                console.log("valid so far 2", validAssetsSoFar);
                self.setState({selectedAssets: validAssetsSoFar, loading: false}, () => {
                    if (self.props.inPage) self.props.onUpdate(validAssetsSoFar, self.state, self.filteredAssetList)
                });
            }
        }
    }

    getList(type, searchTerm) {
        switch (type) {
            case 'site':
                return GridDuck.getSites({
                    filters: [{
                        field: 'search',
                        value: searchTerm
                    }]
                }).then(function (siteList) {
                    return Promise.resolve(
                        siteList.list.map(
                            s => ({
                                id: s.id,
                                title: s.name
                            })
                        )
                    )
                })
            case 'group':
                return GridDuck.getGroups({
                    filters: [{
                        field: 'search',
                        value: searchTerm
                    }]
                }).then(function (groupList) {
                    return Promise.resolve(
                        groupList.list.map(
                            s => ({
                                id: s.id,
                                title: s.name
                            })
                        )
                    )
                })
            case 'Site Group':
                return GridDuck.getSiteGroups({
                    filters: [{
                        field: 'search',
                        value: searchTerm
                    }]
                }).then(function (groupList) {
                    return Promise.resolve(
                        groupList.list.map(
                            s => ({
                                id: s.id,
                                title: s.name
                            })
                        )
                    )
                })
            default:
                break;
        }
        return Promise.resolve([
            {
                id: 1,
                title: searchTerm
            }
        ]);
    }

    componentDidMount() {
        if (!this.drawerFooter) {
            this.drawerFooter = document.getElementById('drawer-footer');
            console.log(this.drawerFooter, ' : drawer footer');
            this.setState({updateRef: 1});
        }
    }

    getFilters() {
        let self = this;
        let filters = [];
        if (this.state.filters) {
            let filterItems = [
                {
                    id: 'OR',
                    value: 'OR',
                    title: 'or'
                }];
            if (self.state.filters.filter(f => f.type === 'site').length < 2 && self.props.inPage !== 'report') {
                filterItems.push({
                    id: 'AND',
                    value: 'AND',
                    title: 'and'
                });
            }

            this.state.filters.forEach(function (filter, index) {
                filters.push(
                    <div className={"filter"} key={index + 'f'}>
                        {index !== 0 ? <div className={'and-or'}>
                            <Dropdown
                                key={index + 'dd'}
                                value={self.state.filterLogic}
                                name={'logic'}
                                nolabel='true'
                                fixeditems={filterItems}
                                onChange={ev => self.filterUpdated(index, {logic: ev.target.value})}
                                disabled={self.state.filters.filter(f => f.type === 'site').length > 1}
                            />
                        </div> : ''}
                        <GdAutocomplete
                            key={index + 'gda'}
                            async
                            autoFocus
                            placeholder={filter.type.replace(
                                /\w\S*/g,
                                function (txt) {
                                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                                }
                            ) + ' Name'}
                            value={filter.value}
                            onChange={(ev, item) => {
                                self.filterUpdated(index, {item: item});
                                filter.value = item ? item.title : '';
                            }}
                            getList={(searchTerm) => self.getList(filter.type, searchTerm)}
                            label={filter.name}
                        />
                        <Icon icon={"FaTimes"}
                              color={'gd-red'}
                              key={index + 'i'}
                              size={'14'}
                              onIconClick={() => self.deleteFilter(index)}
                        />

                    </div>
                )
            });
        }
        return filters;
    }

    getSelection() {
        let self = this;
        let filterTypes = [
            {
                icon: {
                    color: 'gd-grey',
                    name: 'FaCubes',
                    size: '12'
                },
                label: 'Device Group',
                onMenuItemClick: function () {
                    let filters = self.state.filters ? self.state.filters : [];
                    filters.push({
                        type: 'group',
                        id: ''
                    });
                    self.setState({
                        filters: filters
                    });
                }
            }
        ];
        if (!this.state.filters || this.state.filterLogic !== 'AND' || this.state.filters.filter(f => f.type === 'site').length === 0) {
            filterTypes.unshift(
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaBuilding',
                        size: '12'
                    },
                    label: 'Site',
                    onMenuItemClick: function () {
                        let filters = self.state.filters ? self.state.filters : [];
                        filters.push({
                            type: 'site',
                            id: ''
                        });
                        self.setState({
                            filters: filters
                        });
                    }
                }
            )
        }
        if (!this.state.filters || this.state.filterLogic !== 'AND' || this.state.filters.filter(f => f.type === 'siteGroup').length === 0) {
            filterTypes.push(
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaGlobeEurope',
                        size: '12'
                    },
                    label: 'Site Group',
                    onMenuItemClick: function () {
                        let filters = self.state.filters ? self.state.filters : [];
                        filters.push({
                            type: 'Site Group',
                            id: ''
                        });
                        self.setState({
                            filters: filters
                        });
                    }
                }
            )
        }
        return (
            <div className={"filtered-selection"}>
                <Menu menuHeader={{title: 'Filter type'}}
                      controlFromElement={<Button label={'Add filter'}/>} menuItems={filterTypes}/>
            </div>
        )
    }

    getIndividualList() {
        let activeList = this.props.ddState && this.props.ddState.selectedAssets ? this.props.ddState.selectedAssets : [];
        console.log(activeList, ' : active list');
        console.log(this.props.ddState, ' : this.props.ddState');
        return Promise.resolve({list: [], total: 0});
    }

    getAvailableIndividualList(retrievalParams) {
        return GridDuck.getAssets(retrievalParams);
    }

    saveIndividualList(list) {
        console.log(list, ' : list abc');
        let dataOptions = [];
        let self = this;
        let wantedStateOptions = [];
        self.state.selectedAssets.forEach(function (currentlySelectedItem) {
            wantedStateOptions.push(StateOptions.find(sO => sO.type === currentlySelectedItem.sku) || StateOptions.find(sO => sO.type === 'default'));
        });
        wantedStateOptions
            .forEach(function (stateOption) {
                stateOption.dataTypes
                    .forEach(function (dataType) {
                        if (!dataOptions.find(dO => dO.value === dataType.type)) {
                            let dataOption = {
                                value: dataType.type,
                                title: dataType.label + " (" + dataType.unit + ")",
                            };
                            if (~dataType.type.indexOf('SPEND')) {
                                dataOption.title = dataType.label;
                            }
                            dataOptions.push(dataOption);
                        }
                    });
            });
        let dataType = self.state.dataType;
        if (dataOptions.length > 0 && !dataOptions.find(dT => dT.value === dataType)) {
            if (dataOptions.find(dT => dT.value === 'DELIVERED')) {
                dataType = 'DELIVERED';
            } else {
                dataType = dataOptions[0].value;
            }
        }
        this.setState({
            dataType: dataType,
            selectedAssets: list,
            isEditing: false
        }, () => {
            if (this.props.inPage) this.props.onUpdate(list, this.state, this.filteredAssetList)
        });
        return Promise.resolve();
    }

    setCustomGranularity(e) {
        this.setState({
            customGranularity: e.target.value
        });
    }

    rangeUpdated(dateTime) {
        this.setState({
            dateRange: dateTime
        });
    }

    render() {
        let self = this;
        let dateSelection;
        let currentlySelected;
        let filterNotice;
        let loader;
        let createButton;
        if (!this.drawerFooter) {
            this.drawerFooter = document.getElementById('drawer-footer');
            console.log(this.drawerFooter, ' : drawer footer');
        }
        let optionVals = [{
            label: "Filtered (Site, Device Group, Site Group)",
            name: 'filtered',
            disabled: this.state.loading,
            value: 'filtered'
        }, {
            label: "Individually selected (Device name, Site name)",
            name: 'individual',
            disabled: this.state.loading,
            value: 'individual'
        }];

        if (this.props.inPage !== 'report') {
            console.log('remove all from option vals')
            optionVals.unshift({
                label: 'All',
                name: 'all',
                disabled: this.state.loading,
                value: 'all'
            });
        }
        if (this.state.loading) {
            loader = <GenericLoader text={"Fetching"} textLineTwo={"Devices..."}/>
        }
        if (this.state.filters && this.state.filters.filter(f => f.type === 'site').length > 1) {
            filterNotice = (
                <p className={"filter-notice"}>Can only filter by OR when including multiple sites as they
                    have a one-to-one relationship with devices.</p>
            )
        }
        if (this.state.filters && this.state.filterLogic !== 'OR') {
            filterNotice = (
                <p className={"filter-notice"}>To filter by multiple sites use OR</p>
            )
        }
        if (this.state.selectedAssets && this.state.selectedAssets.length > 0) {
            currentlySelected = (
                <p className={'no-sel'}>{this.state.selectedAssets.length} devices currently selected</p>
            )
            let customInput;
            if (this.state.granularity === 'custom') {
                customInput = [(
                    <Input top placeholder={"Granularity in seconds"} type={'number'} InputProps={{step: 1, min: 1}}
                           value={this.state.customGranularity}
                           label={"Seconds"} onChange={this.setCustomGranularity}/>
                )
                ]
                if (this.state.customGranularity) {
                    customInput.push(
                        <p className={'cust-gran'}>Aka: {moment.duration(this.state.customGranularity, 'seconds').humanize()}</p>
                    )
                }
            }

            let dataOptions = [];

            let wantedStateOptions = [];
            self.state.selectedAssets.forEach(function (currentlySelectedItem) {
                wantedStateOptions.push(StateOptions.find(sO => sO.type === currentlySelectedItem.sku) || StateOptions.find(sO => sO.type === 'default'));
            });
            wantedStateOptions
                .forEach(function (stateOption) {
                    stateOption.dataTypes
                        .forEach(function (dataType) {
                            if (!dataOptions.find(dO => dO.value === dataType.type)) {
                                let dataOption = {
                                    value: dataType.type,
                                    title: dataType.label + " (" + dataType.unit + ")",
                                };
                                if (~dataType.type.indexOf('SPEND')) {
                                    dataOption.title = dataType.label;
                                }
                                dataOptions.push(dataOption);
                            }
                        });
                });

            let dateSelectValue = this.state.dateRange && this.state.dateRange.start && this.state.dateRange.end ? [new Date(this.state.dateRange.start), new Date(this.state.dateRange.end)] : [];

            dateSelection = (
                <div>
                    <p className={"title-row"}>
                        Date Range
                    </p>
                    <div className={'row'} style={{marginBottom: '15px'}}>
                        <DateTimeRangePicker
                            label={'Start / End Date'}
                            pastOnly
                            cleanable={false} key={'selector'} placement={'auto'} onChange={this.rangeUpdated}
                            nolabel={true} value={dateSelectValue}/>/>
                    </div>
                    <div className={'row'} style={{marginBottom: '15px', alignItems: 'center'}}>
                        <Dropdown label={'Granularity'} onChange={this.setGranularity}
                                  fixeditems={[
                                      {
                                          id: 'custom',
                                          value: 'custom',
                                          title: 'Custom'
                                      },
                                      {
                                          id: 60,
                                          value: 60,
                                          title: 'Per minute'
                                      },
                                      {
                                          id: 3600,
                                          value: 3600,
                                          title: 'Per hour'
                                      },
                                      {
                                          id: 86400,
                                          value: 86400,
                                          title: 'Per day'
                                      },
                                      {
                                          id: 604800,
                                          value: 604800,
                                          title: 'Per week'
                                      },
                                  ]}
                                  value={this.state.granularity}/>
                        {customInput}
                    </div>
                    <div className={'row'} style={{marginBottom: '15px', alignItems: 'center'}}>
                        <Dropdown label={'Data Type'}
                                  onChange={(e) => this.setState({dataType: e.target.value})}
                                  fixeditems={dataOptions}
                                  value={this.state.dataType}/>
                    </div>
                </div>
            );
        }

        let selectionComponent;
        let filters;
        switch (this.state.selectionType) {
            case 'filtered':
                selectionComponent = this.getSelection();
                filters = this.getFilters();
                break;
            case 'individual':
                if (!this.state.listSaved) {
                    selectionComponent = (
                        <EditableList
                            isEditing={true}
                            noCancel={true}
                            noSave={true}
                            columns={[
                                {
                                    component: StandardListCell,
                                    bold: true,
                                    sortable: true,
                                    title: 'Name',
                                    field: 'name'
                                },
                                {
                                    component: StandardListCell,
                                    sortable: false,
                                    title: 'Site Name',
                                    field: 'siteName'
                                },
                            ]}
                            editingColumns={[
                                {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                            ]}
                            getActiveList={this.getIndividualList}
                            getAvailableList={this.getAvailableIndividualList}
                            onChange={this.saveIndividualList}
                            availableFilters={this.props.onlyAllow}
                        />
                    )
                } else {
                    selectionComponent = (
                        <Button label={"Edit selection"} color={'gd-grey'}
                                onClick={() => this.setState({listSaved: false})}/>
                    )
                }
                break;
            default:
                break;
        }

        return (
            !this.props.inPage && this.state ?
                <div className={"inner-rule DataDownload"}>
                    <div className={"details"}>
                        <div className={'details-inner'}>
                            <div className={'inner-body'}>
                                <p className={"title-row"}>
                                    Devices
                                </p>


                                <GdRadio
                                    onChange={this.selectionChanged}
                                    value={this.state.selectionType} row options={optionVals}/>
                                {loader}
                                {filterNotice}
                                {filters}
                                {selectionComponent}
                                {currentlySelected}
                                {dateSelection}
                                {this.drawerFooter ? createPortal(<div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row-reverse',
                                        flex: '1 1'
                                    }}>
                                    <Button color={'gd-green'} label={"Create Data Request"}
                                            disabled={!this.isValidDate()}
                                            additionalclasses={'md'}
                                            onClick={this.createDataRequest}
                                            progressRes={true}/></div>, this.drawerFooter) : null}
                            </div>
                        </div>
                    </div>
                </div> :
                <div className={'DataDownload'}>
                    <Card>
                        {this.state ? <div style={{width: '100%'}}>
                            <GdRadio
                                onChange={this.selectionChanged}
                                value={this.state.selectionType} row options={optionVals}/>

                            {loader}
                            {filterNotice}
                            {filters}
                            {selectionComponent}
                            {currentlySelected}
                        </div> : ''}
                    </Card>
                </div>
        )
    }
}

export default CreateDataDownload;
