import React, {Component} from "react";
// import "./AddGroupModal.scss";
import '../../styles/_layout.scss';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Dropdown, EditableList,
    GdAutocomplete,
    Icon,
    Input,
    StandardListCell,
    Tabs
} from "gd-react";
import GridDuck from "gridduck";
import SaveBar from "../../components/SaveBar/SaveBar";
import history from "../../meta/history";
import Toggle from "../../components/Toggle/Toggle";
import GdModal from "../../components/GdModal/GdModal";

class EditReportModal extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.moveToTab = this.moveToTab.bind(this);
        this.recipientsChanged = this.recipientsChanged.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        this.errors = {};
        this.changed = false;

        this.state = {
            wide: false,
            deleteConfirmation: '',
            report: {
                name: this.props.item.name,
                recipients: this.props.item.recipients,
                perDevice: this.props.item.perDevice ? 'true' : 'false',
                includeMegaPdf: this.props.item.includeMegaPdf ? 'true' : 'false'
            },
            tabs: [[
                {
                    id: 1,
                    title: 'Details',
                    selected: (this.props.openOnTab === 1 || !this.props.openOnTab),
                    onTabClick: this.onTabClick

                },
                {
                    id: 2,
                    title: 'Recipients',
                    onTabClick: this.onTabClick,
                    selected: (this.props.openOnTab === 2)
                },
                {
                    id: 3,
                    title: 'Delete',
                    onTabClick: this.onTabClick,
                    selected: (this.props.openOnTab === 3)
                }
            ]]
        }

        console.log(this.props.item, ' : report');
        console.log(this.state.report, ' : report');

        this.modalClosed = false;
    }

    componentDidMount() {
        this.checkErrors();
    }

    deleteReport() {
        let self = this;
        return this.props.item.delete().then(function () {
            if (self.props.onDelete) self.props.onDelete();
            self.closeModal();
        })
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    onFormChange(val) {
        this.state.report[val.target.name] = val.target.value;
        this.setState({report: this.state.report});
    }

    hasChanged() {
        if (!this.state.report && !this.props.item) return;
        return this.props.item.name !== this.state.report.name || this.props.item.perDevice.toString() !== this.state.report.perDevice || this.props.item.includeMegaPdf.toString() !== this.state.report.includeMegaPdf || this.props.item.recipients !== this.state.report.recipients;
    }

    saveChanges() {
        let self = this;
        let updatedReportProps = {
            name: this.state.report.name,
            includeMegaPdf: this.state.report.includeMegaPdf === 'true',
            perDevice: this.state.report.perDevice === 'true',
            recipients: this.state.report.recipients
        };

        return this.props.item.set(updatedReportProps).then(function () {
            if (self.props.onSave) self.props.onSave();
            self.closeModal();
        })
    }

    checkErrors() {
        let errors = {};
        if (!this.state.report || !this.state.report.name) errors['name'] = true;
        if (!this.state.report.recipients || !this.state.report.recipients.length) errors['recipients'] = true;

        this.errors = errors;
    }

    onDeleteChange(val) {
        this.setState({deleteConfirmation: val.target.value});
    }

    recipientsChanged(a, b, itemsToAddRemove) {
        let newRecipientList = this.props.item.recipients.filter(itemId => !itemsToAddRemove.itemsToRemove.find(i => i.username === itemId)).concat(itemsToAddRemove.itemsToAdd.map(i => i.username));
        let report = this.state.report;
        report.recipients = newRecipientList;
        console.log(report.recipients, ' : report.recipients');
        this.setState(prevState => ({
            report: report
        }));
    }

    getActiveList() {
        let self = this;
        if (!this.recipients) {
            return GridDuck.getOrganisationMembers({getAll: true}).then(function (memberList) {
                let actualList = memberList.list.filter(m => self.props.item.recipients && self.props.item.recipients.indexOf(m.username) !== -1);
                self.recipients = actualList;
                return Promise.resolve({list: actualList, total: actualList.length})
            })
        } else {
            return Promise.resolve({list: this.recipients, total: this.recipients.length})
        }
    }

    async getAvailableList(params) {
        const members = await GridDuck.getOrganisationMembers(params);
        members.list = members.list.filter(a => !this.props.item.recipients.includes(a.username));
        members.total = members.total -= this.props.item.recipients.length;
        return Promise.resolve(members);
    }

    moveToTab() {
        this.setState({triedToNext: true})
        if (Object.keys(this.errors).length > 0) return;
        this.onTabClick(null, {id: 2});
    }

    render() {
        this.checkErrors();

        let footer;
        // if (this.state.tabs[0][0].selected) footer = <Button label={'Next'} onClick={() => this.moveToTab(1)}/>
        if (this.hasChanged()) footer =
            <Button progressRes disabled={Object.keys(this.errors).length > 0} additionalclasses={'sm'} label={'Save'}
                    color={'gd-green'}
                    onClick={this.saveChanges}/>
        if (this.state.tabs[0][2].selected) footer =
            <Button progressRes disabled={this.state.deleteConfirmation !== 'DELETE'} additionalclasses={'md'}
                    label={'Permanently Delete'} color={'gd-red'}
                    onClick={this.deleteReport}/>

        return (
            <GdModal
                title={this.props.item.name + ' Report'}
                open={this.props.open}
                wide={this.state.tabs[0][1].selected}
                contentLabel={'Edit Report'}
                footer={footer}
                onClose={this.closeModal}>
                {this.state.tabs ? <Tabs tabData={this.state.tabs}/> : null}
                {this.state.tabs[0][0].selected ?
                    <CardBody>
                        <div>
                            <div className={'row'}>
                                <Input required error={this.errors['name'] && this.state.triedToSave} top='true'
                                       onChange={this.onFormChange}
                                       name={'name'} value={this.state.report.name} label={'Name'}/>
                            </div>
                            {(this.props.retrievedAssetIds ? this.props.retrievedAssetIds.length : this.props.item.assetIds.length) > 1 ?
                                <div style={{margin: '15px 0'}} className={"row"}>
                                    <Toggle onClick={(val) => {
                                        this.state.report.perDevice = val;
                                        this.setState({report: this.state.report});
                                    }}
                                            inactivestatename={'Send individual report per device as well as the total'}
                                            activestatename={'Send individual report per device as well as the total'}
                                            active={this.state.report.perDevice}/>
                                </div> : ''}
                            {(this.props.retrievedAssetIds ? this.props.retrievedAssetIds.length : this.props.item.assetIds.length) > 1 && this.state.report.perDevice === 'true' ?
                                <div style={{margin: '15px 0'}} className={"row"}>
                                    <Toggle onClick={(val) => {
                                        this.state.report.includeMegaPdf = val;
                                        this.setState({report: this.state.report});
                                    }}
                                            inactivestatename={'Bundle all device reports into one PDF'}
                                            activestatename={'Bundle all device reports into one PDF'}
                                            active={this.state.report.includeMegaPdf}/>
                                </div> : null}
                        </div>
                    </CardBody> : ''}
                {this.state.tabs[0][1].selected ?
                    <CardBody wide additionalclasses={'no-padding'}>
                        <EditableList
                            isEditing={true}
                            key={1}
                            onChange={this.recipientsChanged}
                            noCancel={true}
                            noSave
                            getActiveList={this.getActiveList}
                            getAvailableList={this.getAvailableList}
                        />
                    </CardBody>
                    : ''}
                {this.state.tabs[0][2].selected ?
                    <CardBody>
                        <div>
                            <div className={'row'}>
                                <Input required error={this.errors['delete'] && this.state.triedToDelete} top='true'
                                       onChange={(e) => this.setState({deleteConfirmation: e.target.value})}
                                       placeholder={'Type DELETE here'}
                                       name={'delete'} value={this.state.deleteConfirmation}
                                       label={'Enter DELETE (case sensitive) in the input and then click the button bellow'}/>
                            </div>
                            {/*<div className={'row'} style={{margin: '15px 0', flexDirection: 'row-reverse'}}>*/}
                            {/*    */}
                            {/*</div>*/}
                        </div>
                    </CardBody> : ''}
            </GdModal>
        )
    }

}

export default EditReportModal;