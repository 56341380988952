import React from "react";
import TemplateCard from "./TemplateCard";
import history from "../../meta/history";
import GridDuck from "gridduck";

class CreateRulePageSelectTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allowAdvancedRules: false
        };
        let self = this;
    }

    render() {
        return (
            <div className={"select-template"}>
                {/*<TemplateCard onClick={() => this.props.updateRuleInProgress({template: 'scratch'})} type={'scratch'}/>*/}
                <div className={"modal-question-container"}>
                    <p className={'question'}>What automation would you like to make?</p>
                </div>
                <TemplateCard onClick={() => this.props.onTemplateChange('timeswitch', 'Timeswitch')} type={'timeswitch'}/>
                {/*<TemplateCard onClick={() => history.push("/rules/create/alert")} type={'alert'}/>*/}
                {/*<TemplateCard onClick={() => this.props.onTemplateChange('thermostat', 'Thermostat')} type={'thermostat'}/>*/}
                {/*<TemplateCard onClick={() => this.props.updateRuleInProgress({template: 'balancing'})} type={'balancing'}/>*/}
                <TemplateCard onClick={() => this.props.onTemplateChange('advanced', 'Custom Automation')} type={'advanced'}/>
                {/*Price Locked: Renewables Balancing*/}
            </div>
        )
    }
}

export default CreateRulePageSelectTemplate;
