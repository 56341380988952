import React, {Component} from "react";
import "./AddDataDownloadModal.scss";
import '../../styles/_layout.scss';
import {CardBody} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import GdModal from "../../components/GdModal/GdModal";
import CreateDataDownload from "../../pages/dataDownload/CreateDataDownload";

class AddDataDownloadModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.state = {}
        this.modalClosed = false;
    }

    closeModal() {
        console.log("Close Data Download Modal");
        this.modalClosed = true;
        this.props.onClose();
    }

    render() {
        return (
            <GdModal
                open={this.props.open}
                contentLabel={'Download Data'}
                title={'Download Data'}
                wide
                footer={true}
                onClose={this.closeModal}>
                <CardBody >
                    <CreateDataDownload onsubmit={this.closeModal} dataLoaded={true}/>
                </CardBody>
            </GdModal>
        )
    }
}

export default AddDataDownloadModal;
