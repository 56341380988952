import React from "react";
import "./TabbedPage.scss";
import {Tabs, Loader} from "gd-react";
import history from "../../meta/history";
import GenericLoader from "../GenericLoader";
import IssuePopover from "../IssuePopover/IssuePopover";

class TabbedPage extends React.Component {
    constructor(props) {
        super(props);
        this.changeTab = this.changeTab.bind(this);
        this.getCurrentTab = this.getCurrentTab.bind(this);
        this.getTabListData = this.getTabListData.bind(this);
        this.getTabData = this.getTabData.bind(this);
        console.log('Reload tabbed page');
    }

    changeTab(urls) {
        let self = this;
        console.log(urls, ' : urls from tab click');
        return function () {
            let splitUrl = self.props.match.path.split('/:');
            let url = splitUrl[0];
            splitUrl.splice(0, 1);
            splitUrl.forEach(function (parameter) {
                if (parameter.substr(0, 7) !== 'subpage') {
                    url += "/" + self.props.match.params[parameter];
                }
            });
            urls.forEach(function (urlToAdd) {
                if (urlToAdd) {
                    url += "/" + urlToAdd;
                }
            });
            console.log(window.location, ' : window location');
            // window._current_tab = urls;
            // window._current_params = window.location.search;
            let root_page = window.location.pathname.split('/')[1];
            // console.log(root_page, ' : root page');
            // console.log(urls, ' : urls')
            // window._set_from_memory = (root_page !== urls[1]);
            history.push(url);
        }
    }

    addTabToUrl() {
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        let updated;
        console.log(window._current_tab, ' : window._current_tab');
        window._current_tab.forEach(ct => {
            if (!~window.location.pathname.indexOf(ct)) {
                updated = true;
                newurl += ('/' + ct);
            }
        })
        if (updated) {
            window.history.replaceState({path: newurl}, '', newurl);
        }
    }

    getCurrentTab(tabs, selectedPages, subPages = [], indexes = []) {
        let wantedTab;
        //wanted tab is the one which matches the nth item in selected pages
        wantedTab = tabs.find(t => t.url === selectedPages[subPages.length]);
        //if not this and there is some data, then wanted tab is the one which is null
        if (!wantedTab && selectedPages.length > subPages.length) {
            wantedTab = tabs.find(t => t.url === null);
            let newItems = selectedPages.slice(subPages.length);
            newItems.unshift(null);
            let oldItems = selectedPages.slice(0, subPages.length);
            selectedPages = oldItems.concat(newItems);
        }
        //if not this then it's the default one
        if (!wantedTab) {
            wantedTab = tabs.find(t => t.default);
        }

        indexes.push(tabs.indexOf(wantedTab));
        subPages.push(wantedTab.url);
        if (wantedTab.component) {
            window._current_tab.push(wantedTab.url);
            console.log(wantedTab, ' : wanted tab 61');
            return {
                subPages: subPages,
                component: wantedTab.component,
                indexes: indexes,
                tab: wantedTab
            }
        } else if (wantedTab.tabs && wantedTab.tabs.length > 0) {
            window._current_tab.push(wantedTab.url);
            return this.getCurrentTab(wantedTab.tabs, selectedPages, subPages, indexes);
        } else {
            console.log(wantedTab, ' : wanted tab');
            window._current_tab.push(wantedTab.url);
            wantedTab = tabs.find(t => t.default);
            return {
                subPages: subPages,
                component: wantedTab.component,
                indexes: indexes,
                tab: wantedTab
            }
        }
    };

    getTabListData(tabs, currentTab, parentUrl = null) {
        if (parentUrl) {
            parentUrl += '/';
        } else parentUrl = '';
        let self = this;
        const _tabs = tabs.map(function (tab) {
            let url = parentUrl + tab.url;
            let onClick;
            let tabsToClick = [];
            tabsToClick.push(url);
            if (!tab.callback) {
                onClick = self.changeTab(tabsToClick);
            } else {
                onClick = tab.callback;
            }

            if (url === (parentUrl + currentTab.tab.url)) {
                console.log({
                    currentTabUrl: currentTab.tab.url,
                    tabUrl: tab,
                    parentUrl: parentUrl,
                    windowLocation: window.location.pathname
                }, ' : parentUrl + currentTab.tab.url');
            }

            return {
                title: tab.title,
                hideRow: tab.hideRow,
                url: url,
                icon: tab.icon,
                endAdornment: tab.endAdornment,
                count: tab.count,
                color: tab.color,
                selected: window.location.pathname.replace(/^\/[^\/]+\//, '') === url,
                onTabClick: tab.onTabClick || onClick,
                tabs: tab.tabs && tab.tabs.length ? self.getTabListData(tab.tabs, currentTab, url) : null
            }
        });
        console.log(_tabs, ' : _tabs');
        return _tabs;
    }

    getTabData(tabs, defaultTab, rows = []) {
        let thisRow = [];
        let thisSelectedTab;
        let self = this;
        tabs.forEach(function (tab, index) {
            let isSelected = (index === defaultTab.indexes[rows.length]);
            if (isSelected) {
                thisSelectedTab = tab;
            }
            let onClick;
            let tabsToClick = [];
            rows.forEach(function (row) {
                row.forEach(function (tab) {
                    if (tab.selected) {
                        tabsToClick.push(tab.url)
                    }
                });
            });
            tabsToClick.push(tab.url);
            if (!tab.callback) {
                onClick = self.changeTab(tabsToClick);
            } else {
                onClick = tab.callback;
            }
            if (tab.component || (tab.tabs && tab.tabs.length > 0) || tab.onTabClick) {
                thisRow.push({
                    title: tab.title,
                    hideRow: tab.hideRow,
                    url: tab.url,
                    icon: tab.icon,
                    endAdornment: tab.endAdornment,
                    count: tab.count,
                    color: tab.color,
                    selected: isSelected,
                    onTabClick: tab.onTabClick || onClick
                });
            }
        });
        rows.push(thisRow);
        if (thisSelectedTab && thisSelectedTab.tabs) {
            return this.getTabData(thisSelectedTab.tabs, defaultTab, rows);
        } else {
            return rows;
        }
    };

    render() {
        let selectedPages = [];
        for (let i = 1; i < 100; i++) {
            let subpage = this.props.match.params['subpage' + i];
            if (subpage) {
                selectedPages.push(subpage);
            } else {
                break;
            }
        }

        window._current_tab = [];
        let currentTab = this.getCurrentTab(this.props.tabs, selectedPages);
        this.addTabToUrl();
        if (!currentTab || !currentTab.component) {
            return (
                <GenericLoader/>
            )
        } else {
            console.log(currentTab, ' : currentTab');
            let tabData = this.props.list ? this.getTabListData(this.props.tabs, currentTab) : this.getTabData(this.props.tabs, currentTab);
            console.log(tabData, ' : _tabData');
            let Component = currentTab.component;
            return (
                <div className={"tabbed-page " + this.props.className}>
                    {this.props.leftpanel ? this.props.leftpanel : null}
                    {this.props.dataLoaded ?
                        <div className={`inner-tabbed-page ${this.props.row ? 'row-override' : ''}`}>
                            {this.props.header ? this.props.header : null}
                            <Tabs list={this.props.list} isMobile={this.props.isMobile}
                                  disabled={!this.props.loaded && this.props.loaded !== undefined} tabData={tabData}/>
                            <Component changeTab={this.changeTab} {...this.props} tab={currentTab}
                                       item={this.props.item}/>
                        </div> : <GenericLoader/>}
                </div>
            )
        }
    }
}

export default TabbedPage;
