import React from "react";
import {
    StateOptions,
    Button,
    Dropdown,
    EditableList,
    Icon,
    Input,
    StandardListCell,
    ScheduleSetter,
    ScheduleTimeline,
    GdCheckbox
} from "gd-react";
import {Prompt} from "react-router-dom";
import "../Rules.scss";
import GridDuck from "gridduck";
import Modal from "react-modal";
import {createPortal} from "react-dom";
import history from "../../meta/history";
import {legacyRule} from "../../services/parser";
import GetNames from "../../services/GetNames";
import _ from 'lodash';

class EditRulePageAlert extends React.Component {
    constructor(props) {
        super(props);
        // let rule = legacyRule(this.props.rule);
        let rule = legacyRule(this.props.rule);
        this.state = {
            customPeriod: rule.customPeriod,
            customThrottleDuration: rule.customThrottleDuration,
            page: 1,
            assetNames: 'Select Devices',
            assetListFilters: [
                {
                    field: 'dataType',
                    value: rule.dataType
                }
            ],
            allTheTime: !rule.isSchedule,
            rule: rule
        };
        this.rule = _.cloneDeep(rule);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.rulesUpdated = this.rulesUpdated.bind(this);
        let self = this;
        if (rule.assets && rule.assets.length > 0) {
            let promises = [];
            rule.assets.forEach(function (a) {
                promises.push(
                    GridDuck.getAsset({id: a})
                )
            });
            Promise.all(promises).then(function (assets) {
                let assetNames = assets.map(a => a.name + ' (' + a.siteName + ')').join(", ");
                let chosenType;
                StateOptions.forEach(function (stateOption) {
                    stateOption.dataTypes.forEach(function (dataType) {
                        if (dataType.type === rule.dataType) {
                            chosenType = dataType;
                        }
                    })
                });

                self.setState({assetNames: assetNames, unit: chosenType.unit, rulesUpdated: true});
            })

        }
        this.setDataType = this.setDataType.bind(this);
        this.setAssetLogic = this.setAssetLogic.bind(this);
        this.selectAssets = this.selectAssets.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.closeAssetsModal = this.closeAssetsModal.bind(this);
        this.setAssets = this.setAssets.bind(this);
        this.setThresholdType = this.setThresholdType.bind(this);
        this.handleThresholdChange = this.handleThresholdChange.bind(this);
        this.setPeriodStartDefined = this.setPeriodStartDefined.bind(this);
        this.setTimeValue = this.setTimeValue.bind(this);
        this.setCustomPeriod = this.setCustomPeriod.bind(this);
        this.setThrottleDuration = this.setThrottleDuration.bind(this);
        this.setCustomThrottlePeriod = this.setCustomThrottlePeriod.bind(this);
        this.blockNext = this.blockNext.bind(this);
        this.getActiveListRecipients = this.getActiveListRecipients.bind(this);
        this.getAvailableListRecipients = this.getAvailableListRecipients.bind(this);
        this.handleRecipientListChange = this.handleRecipientListChange.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.save = this.save.bind(this);
    }

    // assetLogic: "all"
    parseRule(rule) {
        // Default Values
        let alerts = [];
        let triggers = [];
        let isSchedule = false;
        let dataContext = {};
        let throttleDuration;
        let customThrottleDuration;
        let periodDuration;
        let periodStartDefined;
        let customPeriod;
        let schedules = [{
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            state: -1
        }];
        // Legacy/v2 Define dataContext
        if (rule.triggers && rule.triggers.length) dataContext = rule.triggers[0];
        if (rule.actions && rule.actions.length && rule.actions.find(a => a.name === 'bounds')) {
            dataContext = rule.actions.find(a => a.name === 'bounds');
        }
        // Set if dataType is instantaneous
        dataContext.instantaneousData = this.getDataType(dataContext.dataType).isInstantaneous;
        // General definitions
        let inactiveState = -1;
        if (rule.actions && rule.actions.length) {
            throttleDuration = dataContext.throttleDuration;
            periodDuration = dataContext.periodDuration;
            periodStartDefined = dataContext.periodStartDefined;
        } else {
            throttleDuration = rule.throttleDuration;
            periodDuration = rule.periodDuration;
            periodStartDefined = rule.periodStartDefined;
        }
        customThrottleDuration = (throttleDuration !== undefined && throttleDuration !== 86400 && throttleDuration !== 3600 && throttleDuration !== 60);
        customPeriod = (periodStartDefined === null && periodDuration !== 86400 && periodDuration !== 3600 && periodDuration !== 60);
        let assets = dataContext.assets;
        let actions = rule.alerts ? JSON.parse(JSON.stringify(rule.alerts)) : rule.actions;
        // Legacy/v2 Define Asset Logic (all/some)
        if (rule.actions && rule.actions.length) dataContext.requireAll = dataContext.assetLogic === 'all';
        // Legacy/v2 Define Alerts
        if (rule.alerts && rule.alerts.length) alerts = JSON.parse(JSON.stringify(rule.alerts));
        if (rule.actions && rule.actions.length) alerts = [rule.actions.find(a => a.name === 'email')];
        // Legacy/v2 Define Triggers
        if (rule.triggers && rule.triggers.length) triggers = JSON.parse(JSON.stringify(rule.triggers));
        if (rule.actions && rule.actions.length) triggers = [dataContext];
        // Legacy/v2 Define isSchedule
        if (rule.actions && rule.actions.length && rule.actions.find(a => a.isSchedule)) isSchedule = !!rule.actions.find(a => a.isSchedule);
        // Legacy/v2 Define Schedules
        if (isSchedule) {
            if (rule.triggers && rule.triggers.length) schedules = JSON.parse(JSON.stringify(rule.triggers.map(t => t.details)));
            if (rule.actions && rule.actions.length && rule.actions.find(a => a.isSchedule) && rule.actions.find(a => a.isSchedule).schedules) schedules = rule.actions.find(a => a.isSchedule).schedules;
        }

        return {
            id: rule.id,
            name: rule.name,
            description: rule.description,
            inactiveState: inactiveState,
            dataContext: dataContext,
            throttleDuration: throttleDuration,
            periodDuration: periodDuration,
            periodStartDefined: periodStartDefined,
            isSchedule: isSchedule,
            schedules: schedules,
            conditions: schedules,
            customPeriod: customPeriod,
            customThrottleDuration: customThrottleDuration,
            alerts: alerts,
            triggers: triggers,
            actions: actions,
            dataType: dataContext.dataType,
            assets: assets,
            creating: rule.creating
        }
    }

    handleRecipientListChange(a, b, itemsToAddRemove) {
        let rule = this.state.rule;
        rule.alerts[0].recipients = _.uniq(this.rule.alerts[0].recipients.filter(itemId => !itemsToAddRemove.itemsToRemove.find(i => i.username === itemId)).concat(itemsToAddRemove.itemsToAdd.map(i => i.username)));
        this.setState(prevState => ({
            rulesUpdated: true,
            rule: rule
        }));
    }

    async getAvailableListRecipients(params) {
        const members = await GridDuck.getOrganisationMembers(params);
        console.log(this.rule.alerts[0].recipients, ' : this.rule.alerts[0].recipients');
        members.list = members.list.filter(a => !this.rule.alerts[0].recipients.includes(a.username));
        members.total = members.total -= this.rule.alerts[0].recipients.length;
        return Promise.resolve(members);
    }

    getActiveListRecipients() {
        let self = this;
        if (!this.recipients) {
            return GridDuck.getOrganisationMembers({getAll: true}).then(function (memberList) {
                let actualList = memberList.list.filter(m => self.rule.alerts[0].recipients && self.rule.alerts[0].recipients.indexOf(m.username) !== -1);
                self.recipients = actualList;
                return Promise.resolve({list: actualList, total: actualList.length})
            })
        } else {
            return Promise.resolve({list: this.recipients, total: this.recipients.length})
        }

    }

    setCustomThrottlePeriod(val) {
        let rule = this.state.rule;
        rule.throttleDuration = val.target.value;
        this.setState({rule: rule, rulesUpdated: true});
    }

    setThrottleDuration(evt) {
        let throttleDuration = {
            id: evt.target.value
        };
        if (throttleDuration.id === 'custom') {
            this.setState({customThrottleDuration: true, rulesUpdated: true})
        } else {
            let rule = this.state.rule;
            rule.throttleDuration = throttleDuration.id;
            this.setState({customThrottleDuration: false, rule: rule, rulesUpdated: true});
        }
    }

    setCustomPeriod(val) {
        let rule = this.state.rule;
        rule.triggers[0].periodDuration = val.target.value;
        this.setState({rule: rule, rulesUpdated: true});
    }

    setTimeValue(evt) {
        let timeValue = {
            id: evt.target.value
        };
        let rule = this.state.rule;
        if (!!this.state.rule.triggers[0].periodStartDefined) {
            rule.triggers[0].periodStartDefined = timeValue.id;
            this.setState({rule: rule, rulesUpdated: true});
        } else {
            if (timeValue.id === 'custom') {
                rule.triggers[0].periodStartDefined = null;
                this.setState({customPeriod: true, rule: rule});
            } else {
                rule.triggers[0].periodDuration = timeValue.id;
                rule.triggers[0].periodStartDefined = null;
                this.setState({customPeriod: false, rule: rule});
            }
        }
    }

    setPeriodStartDefined(evt) {
        let periodStartDefined = {
            id: evt.target.value
        };
        let rule = this.state.rule;
        if (periodStartDefined.id) {
            rule.triggers[0].periodStartDefined = 'week';
        } else {
            rule.triggers[0].periodDuration = 86400;
            rule.triggers[0].periodStartDefined = null;
            rule.throttleDuration = 3600;
        }
        this.setState({rule: rule, rulesUpdated: true});
    }

    handleThresholdChange(val) {
        if (isNaN(val.target.value)) {
            return;
        }
        let rule = this.state.rule;
        let factor = 1;
        if (this.state.rule.triggers[0].dataType === 'TEMPERATURE' || this.state.rule.triggers[0].dataType === 'HUMIDITY') {
            factor = 100;
        }
        if (this.state.rule.triggers[0].dataType === 'CURRENT') {
            factor = 1000;
        }
        rule.triggers[0].threshold = val.target.value * factor;
        this.setState({rule: rule, rulesUpdated: true});
    };

    setThresholdType(evt) {
        let rule = this.state.rule;
        rule.triggers[0].thresholdType = evt.target.value;
        this.setState({rule: rule, rulesUpdated: true});
    }

    async setAssets(assets, itemsToAddRemove) {
        let rule = this.state.rule;
        let newAssetIds = this.rule.triggers[0].assets.filter(siteId => !itemsToAddRemove.itemsToRemove.find(i => i.id === siteId)).concat(itemsToAddRemove.itemsToAdd.map(i => i.id));
        rule.triggers[0].assets = newAssetIds;
        let self = this;
        let promises = [];
        let names = await GetNames('getAsset', newAssetIds);
        this.setState({
            rule: rule,
            rulesUpdated: true,
            selectingAssets: false,
            assetNames: names.join(", ")
        });
        return Promise.resolve();
    }

    closeAssetsModal() {
        this.setState({selectingAssets: false, rulesUpdated: true});
    }

    getActiveList(props) {
        let promises = this.rule.triggers[0].assets.map(a => GridDuck.getAsset({id: a}));
        return Promise.all(promises).then(function (results) {
            console.log("returning", {list: results, total: results.length});
            return Promise.resolve({list: results, total: results.length});
        })
    }

    getAvailableList(params) {
        return GridDuck.getAssets(params);
    }

    selectAssets() {
        this.setState({selectingAssets: true, rulesUpdated: true});
    }

    setAssetLogic(evt) {
        let rule = this.state.rule;
        rule.triggers[0].requireAll = evt.target.value;
        this.setState({rule: rule, rulesUpdated: true});
    }

    isDifferentList(oldList, newList) {
        return !oldList.every((value, index) => value === newList[index]);
    }

    generateActions(rule, allTheTime) {
        let trigger = rule.triggers[0];
        let ruleData = {
            name: rule.name,
            description: rule.description,
            template: 'alert',
            actions: [
                {
                    "name": "bounds",
                    "instantaneous": true,
                    "dataType": trigger.dataType,
                    "threshold": trigger.threshold,
                    "thresholdType": trigger.thresholdType,
                    "assetLogic": trigger.requireAll ? "all" : "some",
                    "isSymmetric": true,
                    "assets": trigger.assets,
                    "inactiveAction": -1,
                    "nullAction": -1,
                    "color": "#FFE1C6"
                },
                {
                    "name": "email",
                    "type": "email",
                    "color": "#FFF7AE",
                    "stateType": "ON",
                    "isAlert": true,
                    "recipients": rule.alerts[0].recipients
                }
            ]
        };
        if (!allTheTime) {
            ruleData.actions.unshift({
                "inactiveAction": -1,
                "activeAction": 1,
                "isSchedule": true,
                "schedules": rule.conditions
            })
        }
        ruleData.actions.forEach((a, i) => {
            a.id = i;
            if (a.name === 'bounds') {
                a.activeAction = ruleData.actions.length - 1;
                if (rule.throttleDuration) {
                    a.throttleDuration = rule.throttleDuration;
                }
                if (trigger.periodStartDefined) {
                    a.periodStartDefined = trigger.periodStartDefined;
                }
                if (trigger.periodDuration) {
                    a.periodDuration = trigger.periodDuration;
                }
            }
        });
        return ruleData;
    }

    async save(data) {
        if (data.cancel && this.props.onSave) {
            this.props.onSave();
            return Promise.resolve();
        }
        this.saved = true;
        let promises = [];
        let n_rule;
        let self = this;
        let ruleData = this.generateActions(this.state.rule, this.state.allTheTime);
        if (this.props.rule.creating) {
            promises.push(
                GridDuck.createRule(ruleData).then(function (newRule) {
                    n_rule = newRule;
                })
            );
        } else {
            await this.props.rule.set(ruleData);
        }
        await Promise.all(promises);
        if (this.props.onSave) this.props.onSave(n_rule);
        return Promise.resolve();
    }

    hasChanged() {
        let oldRule = this.parseRule(this.props.rule);
        if (this.state.saved) {
            return false;
        }
        if (this.props.rule.creating) {
            return false;
        }

        return this.state.rulesUpdated;
    }

    handleNameChange(val) {
        let name = val.target.value;
        this.setState(prevState => ({
            rulesUpdated: true,
            rule: {
                ...prevState.rule,
                name: name
            }
        }));
    }

    handleDescriptionChange(val) {
        let description = val.target.value;
        this.setState(prevState => ({
            rulesUpdated: true,
            rule: {
                ...prevState.rule,
                description: description
            }
        }));
    }

    getDataType(dt) {
        let chosenType, isInstantaneous = false;
        StateOptions.forEach(function (stateOption) {
            stateOption.dataTypes.forEach(function (dataType) {
                if (dataType.type === dt) {
                    chosenType = dataType;
                }
            })
        });

        if (chosenType.graphType !== 'bar') {
            isInstantaneous = true;
        }

        return {
            chosenType: chosenType,
            isInstantaneous: isInstantaneous
        }
    }

    setDataType(evt) {
        let rule = this.state.rule;
        let type = evt.target.value;
        let dataTypeContext = this.getDataType(type);
        rule.triggers[0].dataType = type;
        rule.triggers[0].assets = [];
        rule.triggers[0].instantaneousData = dataTypeContext.isInstantaneous;
        rule.persistent = rule.triggers[0].instantaneousData;
        this.setState({
            rulesUpdated: true,
            rule: rule, assetNames: 'Select Devices', assetListFilters: [
                {
                    field: 'dataType',
                    value: type
                }
            ], unit: dataTypeContext.chosenType.unit
        });
    }

    blockNext() {
        switch (this.state.page) {
            case 1:
                return this.state.rule.name === undefined || this.state.rule.name === null || this.state.rule.name === '';
                break;
            case 2:
                return false;
                break;
            case 3:
                if (this.state.rule.triggers[0].assets.length === 0) {
                    return true;
                }
                if (this.state.rule.triggers[0].threshold === undefined || this.state.rule.triggers[0].threshold === null) {
                    return true;
                }
                return false;
                break;
            case 4:
                if (!this.state.rule.alerts[0].recipients || this.state.rule.alerts[0].recipients.length === 0) {
                    return true;
                }
                break;
        }
        return false;
    }

    rulesUpdated(rules) {
        this.setState(prevState => {
            console.log(rules, ' : timeswitch rules');
            console.log(prevState.rule, ' : prevState.rule');
            return {
                rulesUpdated: true,
                rule: {
                    ...prevState.rule,
                    conditions: rules
                }
            }
        });

    }

    componentDidMount() {
        if (!this.drawerFooter) {
            this.drawerFooter = document.getElementById('drawer-footer');
            this.setState({updateRef: 1});
        }
    }

    render() {
        let saveButton;
        let content;
        let stateOptions = [];
        let self = this;
        if (!this.drawerFooter) {
            this.drawerFooter = document.getElementById('drawer-footer');
        }
        StateOptions.forEach(function (stateOption) {
            stateOption.options.forEach(function (option) {
                if (!option.nonSelectable && !stateOptions.find(sO => sO.value === option.state)) {
                    stateOptions.push({
                        value: option.state,
                        title: option.label,
                        color: option.color
                    });
                }
            });
        });

        let factor = 1;
        if (this.state.rule.triggers[0].dataType === 'TEMPERATURE' || this.state.rule.triggers[0].dataType === 'HUMIDITY') {
            factor = 100;
        }
        if (this.state.rule.triggers[0].dataType === 'CURRENT') {
            factor = 1000;
        }
        switch (this.state.page) {
            case 1:
                content = (<div className={"details"}>
                        <div className={'details-inner'}>
                            <div className={'inner-header'}>
                                <p className={"title-row"}>
                                    Details
                                </p>
                                <p className={"help-row"}>
                                    First off some basic details to describe what the alert is for.
                                </p>
                            </div>
                            <div className={'inner-body'}>
                                <div className={"name"}>
                                    <Input autoFocus label={'Name*'} value={this.state.rule.name}
                                           onChange={this.handleNameChange}/>
                                </div>
                                <div className={"description"}>
                                    <Input label={'Description'} value={this.state.rule.description}
                                           multiline
                                           inputProps={{minRows: 3, maxRows: 4}}
                                           onChange={this.handleDescriptionChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 2:
                content = (
                    <div className={"details"}>
                        <div className={'details-inner'}>
                            <div className={'inner-header'}>
                                <p className={"title-row"}>
                                    Times
                                </p>
                                <p className={"help-row"}>
                                    Set the times that the alert will be active.
                                </p>
                            </div>

                            <div className={'inner-body'}>
                                <GdCheckbox checked={this.state.allTheTime} name={'allTheTime'}
                                            onChange={(e) => {
                                                this.setState({allTheTime: e.target.checked, rulesUpdated: true})
                                            }}
                                            label={'All the time'}/>
                                {!this.state.allTheTime ?
                                    [(<ScheduleTimeline
                                        rules={this.state.rule.conditions}
                                        actions={[{
                                            id: -1,
                                            name: 'Active',
                                            color: '#49c570'
                                        }]}
                                        inactiveState={'inactive'}/>),
                                        (<ScheduleSetter rules={this.state.rule.conditions}
                                                         actions={[{
                                                             id: -1,
                                                             name: 'Active',
                                                             color: '#49c570'
                                                         }]}
                                                         hideActionList
                                                         defaultState={-1}
                                                         inactiveState={this.state.rule.inactiveState}
                                                         onUpdate={this.rulesUpdated}/>)] : null}
                            </div>
                        </div>
                    </div>
                )
                break;
            case 3:
                let dataOptions = [];
                StateOptions.forEach(function (stateOption) {
                    stateOption.dataTypes.forEach(function (dataType) {
                        if (!dataOptions.find(dO => dO.value === dataType.type)) {
                            let dataOption = {
                                value: dataType.type,
                                title: (dataType.category !== dataType.label ? dataType.category + ' ' : '') + dataType.label + " (" + dataType.unit + ")",
                                category: dataType.category,
                                summary: dataType.summary,
                                icon: dataType.categoryDetails.icon,
                                color: dataType.categoryDetails.color
                            };
                            dataOptions.push(dataOption);
                        }
                    });
                });
                dataOptions = _.orderBy(dataOptions, ['category'], ['asc']);

                let assetLogicOptions = [
                    {
                        value: false,
                        title: 'For any of',
                    },
                    {
                        value: true,
                        title: 'For all of',
                    },
                ]
                let thresholdTypes = [
                    {
                        value: 'above',
                        title: 'Above',
                    },
                    {
                        value: 'below',
                        title: 'Below',
                    },
                ];
                let timeTypeOptions = [
                    {
                        value: true,
                        title: 'Over the current',
                    },
                    {
                        value: false,
                        title: 'Over the previous',
                    },
                ]
                let definedTimeOptions;
                if (this.state.rule.triggers.length > 0 && !!this.state.rule.triggers[0].periodStartDefined) {
                    definedTimeOptions = [
                        {
                            value: 'year',
                            title: 'Year',
                        },
                        {
                            value: 'month',
                            title: 'Month',
                        },
                        {
                            value: 'week',
                            title: 'Week',
                        },
                        {
                            value: 'day',
                            title: 'Day',
                        },
                        {
                            value: 'hour',
                            title: 'Hour',
                        },
                        {
                            value: 'minute',
                            title: 'Minute',
                        },
                    ]
                } else {
                    definedTimeOptions = [
                        {
                            value: 'custom',
                            title: 'Custom',
                        },
                        {
                            value: 86400,
                            title: '24 hours',
                        },
                        {
                            value: 3600,
                            title: 'Hour',
                        },
                        {
                            value: 60,
                            title: 'Minute',
                        },
                    ]
                }

                let timeType;
                let periodStartDefined;
                if (this.state.rule.triggers.length > 0 && !this.state.rule.triggers[0].instantaneousData) {
                    timeType = (<Dropdown nolabel='true' onChange={this.setPeriodStartDefined}
                                          fixeditems={timeTypeOptions}
                                          value={!!this.state.rule.triggers[0].periodStartDefined}/>);

                    let definedTimeValue;
                    if (!!this.state.rule.triggers[0].periodStartDefined) {
                        definedTimeValue = this.state.rule.triggers[0].periodStartDefined;
                    } else {
                        if (this.state.customPeriod) {
                            definedTimeValue = 'custom';
                        } else {
                            definedTimeValue = this.state.rule.triggers[0].periodDuration;
                        }
                    }


                    periodStartDefined = (
                        <Dropdown nolabel='true' onChange={this.setTimeValue}
                                  fixeditems={definedTimeOptions} value={definedTimeValue}/>);
                }

                let customPeriod;
                if (this.state.rule.triggers.length > 0 && this.state.rule.triggers[0].periodStartDefined === null && this.state.customPeriod) {
                    customPeriod = (
                        <span style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: '1 1',
                            minWidth: '200px'
                        }}>
							<Input nolabel="true" label={''} value={this.state.rule.triggers[0].periodDuration}
                                   onChange={this.setCustomPeriod}/> <p style={{'margin-left': '10px'}}>seconds</p>
						</span>
                    );
                }

                let throttleDuration;
                let customThrottlePeriod;
                if (this.state.customThrottleDuration) {
                    customThrottlePeriod = [
                        (<p>every</p>),
                        (<Input nolabel="true" label={''} value={this.state.rule.throttleDuration}
                                onChange={this.setCustomThrottlePeriod}/>), (
                            <p>seconds</p>)];
                }
                if (this.state.rule.triggers.length > 0 && !this.state.rule.triggers[0].periodStartDefined && !this.state.rule.triggers[0].instantaneousData) {
                    let throttleDurationOptions = [
                        {
                            value: 'custom',
                            title: 'Custom',
                        },
                        {
                            value: 86400,
                            title: 'Every 24 hours',
                        },
                        {
                            value: 3600,
                            title: 'Every Hour',
                        },
                        {
                            value: 60,
                            title: 'Every Minute',
                        },
                    ]
                    throttleDuration =
                        <div className={'condition-row'}>
                            <p>and inform me</p>
                            <Dropdown nolabel='true' fixeditems={throttleDurationOptions}
                                      onChange={this.setThrottleDuration}
                                      value={this.state.customThrottleDuration ? 'custom' : this.state.rule.throttleDuration}/> {customThrottlePeriod}
                        </div>
                }
                content = (<div className={"details"}>
                    <div className={'details-inner'}>
                        <div className={'inner-header'}>
                            <p className={"title-row"}>
                                Conditions
                            </p>
                            <p className={"help-row"} style={{marginBottom: '20px'}}>
                                This is where you define what triggers your notifications
                            </p>
                        </div>

                        <div className={'inner-body'}>
                            <div className={'condition-row'}>
                                <p>If the</p>
                                <Dropdown nolabel='true' onChange={this.setDataType}
                                          value={this.state.rule.triggers[0].dataType}
                                          fixeditems={dataOptions}/>
                                <Dropdown nolabel='true' onChange={this.setAssetLogic} fixeditems={assetLogicOptions}
                                          value={this.state.rule.triggers[0].requireAll}/>
                            </div>
                            <div className={'condition-row assets'}>
                                <Button label={this.state.assetNames} additionalclasses={'fake-input'} nonBold={true}
                                        color={"gd-grey"}
                                        onClick={this.selectAssets}/>
                            </div>
                            <div className={'condition-row'}>
                                <p>goes</p>
                                <Dropdown nolabel='true' onChange={this.setThresholdType} fixeditems={thresholdTypes}
                                          value={this.state.rule.triggers[0].thresholdType}/>
                                <Input nolabel="true" label={''} placeholder={"Threshold"}
                                       type={"number"}
                                       value={this.state.rule.triggers.length > 0 ? this.state.rule.triggers[0].threshold / factor : null}
                                       onChange={this.handleThresholdChange}/>
                                <p>{this.state.unit}</p>
                            </div>
                            {(this.state.rule.triggers.length > 0 && !this.state.rule.triggers[0].instantaneousData) ?
                                <div className={'condition-row'}>
                                    {timeType}
                                    {periodStartDefined}
                                    {customPeriod}
                                </div>
                                : ''}

                            {throttleDuration}
                        </div>
                    </div>
                </div>)
                break;
            case 4:
                content = (<div className={"details"}>
                    <div className={'details-inner'} style={{overflow: 'hidden'}}>
                        <div className={'inner-header'}>
                            <p className={"title-row"}>
                                Recipients
                            </p>
                            <p className={"help-row"} style={{marginBottom: '20px'}}>
                                Whom this notification will be sent to
                            </p>
                        </div>

                        <div className={'inner-body'}>
                            <EditableList
                                isEditing={true}
                                noCancel={true}
                                key={2}
                                getActiveList={this.getActiveListRecipients}
                                getAvailableList={this.getAvailableListRecipients}
                                save={this.save}
                                noSave={true}
                                onChange={this.handleRecipientListChange}
                            />
                        </div>
                    </div>
                </div>);
        }

        if (this.hasChanged()) {
            saveButton = (
                <Button color={'gd-green'} label={"Save and exit"} progressRes={true} onClick={this.save}
                        onComplete={this.saved}/>);
        }
        return (<div className={"inner-rule"}>
            {/*<div className={"top-bar"}>*/}
            {/*	<Icon icon={'FaTimes'} color={'gd-grey'} size={'25'}*/}
            {/*		  onIconClick={() => history.push(this.props.rule.creating ? '/rules/create' : ('/rule/' + this.state.rule.id))}/>*/}
            {/*	{saveButton}*/}
            {/*</div>*/}
            {content}
            <Modal
                isOpen={this.state.selectingAssets}
                onRequestClose={this.closeAssetsModal}
                contentLabel="Select Alert Devices">
                <EditableList
                    isEditing={true}
                    key={1}
                    noCancel={false}
                    onCancelOnlyCallback={this.closeAssetsModal}
                    editingColumns={[
                        {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                    ]}
                    availableFilters={this.state.assetListFilters}
                    getActiveList={this.getActiveList}
                    getAvailableList={this.getAvailableList}
                    save={this.setAssets}
                />
            </Modal>

            {this.drawerFooter ? createPortal(<div
                style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: '1 1'}}>
                {this.state.rule.creating ?
                    <span/> : null}
                {!this.state.rule.creating && !this.hasChanged() ?
                    <Button label={"Cancel"} outline color={'gd-grey'} onClick={() => this.save({cancel: true})}/> : ''}
                {!this.state.rule.creating && this.hasChanged() ?
                    <Button label={"Save and exit"} additionalclasses={'md'} color={'gd-green'} progressRes={true}
                            onClick={this.save}/> : null}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {this.state.page === 1 ? <span/> : ''}
                    {this.state.page !== 1 ?
                        <div style={{marginRight: '10px', display: 'flex'}}><Button label={"Back"} color={'gd-grey'}
                                                                                    onClick={() => {
                                                                                        let stateUpdate = {page: this.state.page - 1};
                                                                                        if (this.state.page === 4) {
                                                                                            this.state.rule.alerts[0].recipients = this.rule.alerts[0].recipients;
                                                                                            console.log(this.state.rule.alerts[0].recipients, ' : this.state.rule.alerts[0].recipients');
                                                                                        }
                                                                                        this.setState(stateUpdate);
                                                                                    }}/>
                        </div> : ''}
                    {this.state.page !== 4 ? <Button disabled={this.blockNext()} label={"Next"}
                                                     onClick={() => this.setState({page: this.state.page + 1})}/> : ''}
                    {this.state.page === 4 && this.state.rule.creating ?
                        <Button color={'gd-green'} additionalclasses={'sm'} label={"Save"} disabled={this.blockNext()}
                                progressRes={true} onClick={this.save} onComplete={this.saved}/> : ''}
                </div>
            </div>, this.drawerFooter) : null}

            {/*<Prompt*/}
            {/*    when={this.hasChanged()}*/}
            {/*    message="You have unsaved changes. Do you definitely want to leave?"*/}
            {/*/>*/}
        </div>)
    }
}

export default EditRulePageAlert;
