import React, {Component} from "react";
import "./AddOverviewGroupModal.scss";
import '../../styles/_layout.scss';
import {
    Button,
    CardBody,
    EditableList,
    Input,
    Tabs
} from "gd-react";
import GridDuck from "gridduck";
import history from "../../meta/history";
import GdModal from "../../components/GdModal/GdModal";

class AddOverviewGroupModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.sitesInGroupChanged = this.sitesInGroupChanged.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onSiteChange = this.onSiteChange.bind(this);
        this.getActiveList = this.getActiveList.bind(this)
        this.getAvailableList = this.getAvailableList.bind(this)
        this.moveToTab = this.moveToTab.bind(this)
        this.onTabClick = this.onTabClick.bind(this)
        this.errors = {};
        this.changed = false;
        this.state = {
            sitesInGroup: [],
            sites: [],
            siteGroup: {
                name: ''
            },
            tabs: [[
                {
                    id: 1,
                    title: 'Details',
                    selected: true,
                    onTabClick: this.onTabClick

                },
                {
                    id: 2,
                    title: 'Sites',
                    onTabClick: this.onTabClick,
                    disabled: true

                }
            ]]
        }
        this.modalClosed = false;
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    getAvailableList(params) {
        return GridDuck.getSites(params)
    }

    async getActiveList(params) {
        // let self = this
        //
        // if (!this.props.item || !this.props.item.id) return Promise.resolve({list: [], total: 0})
        //
        // if (!this.state.newSitesList.length) {
        //     params.filters = [
        //         {field: '_permission', value: this.perm_levels}
        //     ]
        //     let actualList = await GridDuck.getSites();
        //     let list = actualList.list;
        //     self.setState({newSitesList: actualList});
        //     return Promise.resolve({list: list, total: list.length});
        // } else
        return Promise.resolve({list: [], total: 0});
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    onFormChange(val) {
        this.state.siteGroup[val.target.name] = val.target.value;
        this.setState(this.state);
    }

    onSiteChange(val, newValue) {
        this.state.site = newValue;
        this.setState(this.state);
    }

    saveChanges() {
        let self = this;
        this.setState({triedToSave: true});
        if (Object.keys(this.errors).length > 0) return new Promise(function (resolve, reject) {
            return reject();
        }).catch(function () {
            return Promise.reject();
        });
        return GridDuck.createSiteGroup(this.state.siteGroup).then(function (res) {
            history.push('/siteGroup/' + res.id);
            self.closeModal();
        })
    }

    checkErrors() {
        let errors = {};
        if (!this.state.siteGroup.name || !this.state.siteGroup.name.length) errors['name'] = true;
        this.state.tabs[0][1].disabled = errors['name'];
        // if (!this.state.siteGroup.siteIds || !this.state.siteGroup.siteIds.length) errors['sitesInGroup'] = true;
        this.errors = errors;
        return errors;
    }

    sitesInGroupChanged(newList) {
        this.state.siteGroup.siteIds = newList.map(function (s) {
            return s.id;
        });
        this.setState(this.state)
    }

    moveToTab() {
        this.setState({triedToNext: true})
        if (this.errors['name']) return;
        this.onTabClick(null, {id: 2});
    }

    render() {
        this.checkErrors();
        let footer;
        if (this.state.tabs[0][0].selected) footer = <Button label={'Next'} onClick={() => this.moveToTab(1)}/>
        if (this.state.tabs[0][1].selected) footer =
            <Button progressRes additionalclasses={'sm'} label={'Add group'} color={'gd-green'}
                    onClick={this.saveChanges}/>
        return (
            <GdModal
                title={'Create Site Group'}
                open={this.props.open}
                wide={this.state.tabs[0][1].selected}
                footer={footer}
                onClose={this.closeModal}>
                {this.state.tabs ? <Tabs tabData={this.state.tabs}/> : null}
                {this.state.tabs[0][0].selected ?
                    <CardBody>
                        <div className={'row'}>
                            <Input required
                                   error={this.errors['name'] && this.state.triedToNext}
                                   top='true'
                                   onChange={this.onFormChange}
                                   name={'name'}
                                   value={this.state.siteGroup.name}
                                   label={'Name'}/>
                        </div>
                    </CardBody> : ''}
                {this.state.tabs[0][1].selected ?
                    <CardBody additionalclasses={'no-padding'}> <EditableList
                        isEditing={true}
                        key={1}
                        onChange={this.sitesInGroupChanged}
                        noCancel={true}
                        noSave
                        getActiveList={this.getActiveList}
                        getAvailableList={this.getAvailableList}
                    /></CardBody> : ''}
            </GdModal>
        )
    }
}

export default AddOverviewGroupModal;


