import React from "react";
import GridDuck from "gridduck";
import TabbedPage from "../../components/TabbedPage/TabbedPage";
import "./Account.scss";
import AccountDetails from "./AccountDetails";
import AccountInvoices from "./AccountInvoices";
import AccountSubscriptions from "./AccountSubscriptions";
import AccountSecurity from "./AccountSecurity";
import AccountBilling from "./AccountBilling";
import GenericLoader from "../../components/GenericLoader";
import OrganisationSecurity from "../organisation/OrganisationSecurityPage";
import OrganisationGeneral from "./OrganisationGeneral";
import LocationListPage from "../LocationListPage";
import ModbusModelsListPage from "../ModbusModels/ModbusModelsListPage";
import IntegrationManagement from "../IntegrationManagement/IntegrationManagement";
import TariffListPage from "../TariffListPage";
import CustomFieldListPage from "../CustomFieldListPage";
import SiteOccupancyScheduleListPage from "../SiteOccupancySchedules/SiteOccupancyListPage";
import DeviceCategoryListPage from "../deviceCategory/DeviceCategoryListPage";
import UnitList from "../dataUpload/UnitList";
import DataCategoryListPage from "../dataUpload/DataCategoryList";
import DataUploadListPage from "../dataUpload/DataUploadList";

class AccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId}).then(function (user) {
            GridDuck.getOrganisation({id: user.organisationId}).then(function (org) {
                self.setState({user: user, org: org});
            });
        });
    }

    render() {
        if (this.state && this.state.user) {
            let tabs = [
                {
                    default: true,
                    url: 'account',
                    title: 'Account',
                    tabs: [
                        {
                            default: true,
                            url: 'general',
                            title: 'General',
                            component: AccountDetails
                        },
                        {
                            url: 'security',
                            title: 'Security',
                            component: AccountSecurity
                        }
                    ]
                },
                {
                    url: 'organisation',
                    title: 'Organisation',
                    tabs: [
                        {
                            default: true,
                            url: 'general',
                            title: 'General',
                            component: OrganisationGeneral
                        },
                        {
                            url: 'integrations',
                            title: 'Manage Integrations',
                            component: IntegrationManagement
                        },
                        {
                            url: 'locations',
                            title: 'Addresses',
                            component: LocationListPage
                        },
                        {
                            url: 'tariffs',
                            title: 'Tariffs',
                            component: TariffListPage

                        },
                        {
                            url: 'custom-fields',
                            title: 'Custom Fields',
                            component: CustomFieldListPage
                        },
                        {
                            url: 'modbus-models',
                            title: 'Modbus Models',
                            component: ModbusModelsListPage
                        },
                        {
                            url: 'occupancy-schedules',
                            title: 'Occupancy Schedules',
                            component: SiteOccupancyScheduleListPage
                        },{
                            url: 'device-categories',
                            title: 'Device Categories',
                            component: DeviceCategoryListPage
                        },

                    ]
                },
                {
                    url: 'data-management',
                    title: 'Data Management',
                    icon: 'FaDatabase',
                    tabs: [
                        // {
                        //     default: true,
                        //     url: 'uploads',
                        //     title: 'Uploads',
                        //     component: DataUploadListPage
                        // },
                        {
                            default: true,
                            url: 'categories',
                            icon: 'FaLayerGroup',
                            title: 'Categories',
                            component: DataCategoryListPage
                        },
                        {
                            url: 'custom-unit-systems',
                            icon: 'FaPencilRuler',
                            title: 'Custom Unit Systems',
                            component: UnitList
                        }
                    ]
                }
            ];
            if (this.state.user.orgPermission === 'admin' || this.state.user.orgPermission === 'owner') {
                tabs.push({
                    default: true,
                    url: 'billing-and-shipping',
                    disabled: !this.state.user.stripeId,
                    title: 'Billing & Shipping',
                    tabs: [
                        {
                            default: true,
                            url: 'general',
                            title: 'General',
                            component: AccountBilling
                        }, {
                            url: 'gridduck-invoices',
                            title: 'GridDuck Invoices',
                            component: AccountInvoices
                        }, {
                            url: 'gridduck-subscriptions',
                            title: 'GridDuck Subscriptions',
                            component: AccountSubscriptions
                        }
                    ]
                })

                tabs[1].tabs.push(
                    {
                        url: 'security',
                        title: 'Security',
                        component: OrganisationSecurity
                    })
            }

            return (
                <TabbedPage dataLoaded={this.state.user} tabs={tabs}
                            item={this.state.user}
                            row
                            list
                            org={this.state.org}
                            {...this.props}/>
            )
        } else {
            return <GenericLoader/>
        }

    }
}

export default AccountPage;

