import React from "react";
import {
    Button,
    List, StandardListCell, Toast
} from "gd-react";
import GridDuck from 'gridduck';
import './SiteOccupancySchedulesListPage.scss'
import SiteOccupancyScheduleModal from "../../modals/SiteOccupancyScheduleModal/SiteOccupancyScheduleModal";
import history from "../../meta/history";
import NoItems from "../../components/noItems";

class SiteOccupancyScheduleListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSiteOccupancySchedule: null,
            createSiteOccupancySchedule: false,
            refreshRef: 1
        };
        this.getList = this.getList.bind(this);

    }

    componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {
                console.log(user.orgPermission, ' : user org permission')
                console.log(user, ' : user');
                self.setState({user: user});
                let urlParams = new URLSearchParams(window.location.search);
                let SiteOccupancyScheduleId = urlParams.get('id');
                if (SiteOccupancyScheduleId) {
                    GridDuck.getOccupancySchedule({id: SiteOccupancyScheduleId}).then((model) => {
                        self.setState({selectedSiteOccupancySchedule: model});
                    });
                }
            });
    }

    async getList(params) {
        return GridDuck.getOccupancySchedules(params);
    }

    render() {
        return (
            this.state.user ? <div className={"page site-occupancy-schedules with-header-button"}>
                <div className={'widget-board-header'}>
                    <div className={'row reverse place-in-header'}>
                        {this.state.user.orgPermission !== 'view' ?
                            <Button color={'gd-green'} label={'+ Add New Occupancy Schedule'}
                                    onClick={() => this.setState({
                                        createSiteOccupancySchedule: true
                                    })}/> : null}
                    </div>
                </div>
                <List
                    noitemelem={<NoItems text={'You have no occupancy schedules'}/>}
                    columns={[{
                        component: StandardListCell,
                        title: 'Name',
                        field: 'name',
                        maxWidth: '150px'
                    },
                        {
                            component: StandardListCell,
                            custom: function (item) {
                                return item._permission
                            },
                            title: 'Permission',
                            maxWidth: 'none'
                        }]}
                    getlist={this.getList}
                    refreshRef={this.state.refreshRef}
                    rowOnClick={(item) => {
                        this.setState({selectedSiteOccupancySchedule: item});
                        history.push('/settings/organisation/occupancy-schedules?id=' + item.id);
                    }}
                    filters={[]}/>

                {this.state.selectedSiteOccupancySchedule || this.state.createSiteOccupancySchedule ?
                    <SiteOccupancyScheduleModal
                        orgPerm={this.state.user.orgPermission}
                        item={this.state.selectedSiteOccupancySchedule}
                        onClose={() => {
                            this.setState({
                                createSiteOccupancySchedule: false,
                                selectedSiteOccupancySchedule: null,
                                refreshRef: this.state.refreshRef+1
                            });
                            history.push('/settings/organisation/occupancy-schedules');
                        }}
                        onSave={(msg) => this.setState({showToast: msg})}
                        open={this.state.selectedSiteOccupancySchedule || this.state.createSiteOccupancySchedule}/> : null}
                <Toast onClose={() => this.setState({showToast: false})} message={this.state.showToast}
                       open={!!(this.state.showToast)}
                       severity={this.state.showToast && this.state.showToast.indexOf('Deleted') !== -1 ? "error" : "success"}
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}/>
            </div> : null
        )
    }
}

export default SiteOccupancyScheduleListPage;