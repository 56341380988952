import React, {Component} from "react";
import "./AddDeviceModal.scss";
import '../../styles/_layout.scss';
import DeviceForm from "../../components/DeviceForm";
import {
    Button,
    CardBody,
    Input,
    Dropdown,
    EditableList,
    GdAutocomplete,
    StateOptions
} from "gd-react";
import GridDuck from "gridduck";
import GenericLoader from "../../components/GenericLoader";
import GdModal from "../../components/GdModal/GdModal";
import VIRTUAL_PARENT from '../../images/products/VIRTUAL-PARENT.png'
import PLU_UK from '../../images/products/PLU-UK.png'
import SEN_POW_OWON from '../../images/products/SEN-POW-OWON.png'
import REL_DIN_16A from '../../images/products/REL-DIN-16A.png'
import BLE_PULSE_DELIVERED from '../../images/products/BLE_PULSE_DELIVERED.png'
import SEN_TEM_PROBE from '../../images/products/SEN-TEM-PROBE.png'
import REL_DIN_30A from '../../images/products/REL-DIN-30A.png'
import REL_DIN_32A_OWON from '../../images/products/REL-DIN-32A-OWON.png'
import INL_SWI from '../../images/products/INL-SWI.png'
import MET_REA from '../../images/products/MET-REA.png'
import SEN_TEM_HUM from '../../images/products/SEN-TEM-HUM.png'
import SEN_LIGHT_MOTION from '../../images/products/SEN-LIGHT-MOTION.png'
import NB_IOT_PULSE_DELIVERED from '../../images/products/NB-IOT-PULSE_DELIVERED.png'
import NoItems from "../../components/noItems";

const gatewayDeviceLimit = 17;

class AddManualDeviceModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.errors = {}
        this.errors = {}
        this.state = {
            site: '',
            sites: [],
            noHubs: false,
            errorMessage: '',
            mac: '',
            manualHub: null,
            triedToSave: false,
            addingSingleAsset: null,
            dataType: null,
            deviceTypes: [
                {
                    type: 'VIRTUAL-PARENT',
                    label: 'Virtual Device',
                    subtitle: 'A collection of existing real devices',
                    image: VIRTUAL_PARENT,
                    id: 0
                },
                {
                    type: 'PLU-UK',
                    label: 'UK Smart Plug',
                    macLength: 16,
                    maxInstances: 1,
                    hubRequired: true,
                    image: PLU_UK,
                    id: 1
                },
                {
                    type: 'SEN-POW-OWON',
                    label: '3-Phase Powered Clamp',
                    macLength: 16,
                    maxInstances: 1,
                    hubRequired: true,
                    image: SEN_POW_OWON,
                    id: 2
                },
                {
                    type: 'REL-DIN-16A',
                    label: '16A Relay',
                    macLength: 16,
                    maxInstances: 1,
                    image: REL_DIN_16A,
                    hubRequired: true,
                    id: 5
                },
                {
                    type: 'BLE_PULSE',
                    label: 'Pulse Counter',
                    image: BLE_PULSE_DELIVERED,
                    macLength: 12,
                    hubRequired: true,
                    maxInstances: 3,
                    id: 10
                },
                {
                    type: 'SEN-TEM-PROBE',
                    label: 'Fridge Temperature Probe',
                    image: SEN_TEM_PROBE,
                    macLength: 16,
                    maxInstances: 1,
                    hubRequired: true,
                    id: 11
                },
                {
                    type: 'REL-DIN-32A-OWON',
                    label: '32A Relay',
                    image: REL_DIN_32A_OWON,
                    macLength: 16,
                    maxInstances: 1,
                    hubRequired: true,
                    id: 13
                },
                {
                    type: 'INL-SWI',
                    label: 'Inline Switch',
                    macLength: 16,
                    maxInstances: 1,
                    hubRequired: true,
                    image: INL_SWI,
                    id: 15
                },
                {
                    type: 'MET-REA',
                    label: 'Meter Reader',
                    macLength: 16,
                    maxInstances: 1,
                    hubRequired: true,
                    image: MET_REA,
                    id: 16
                },
                {
                    type: 'SEN-TEM-HUM',
                    label: 'Temperature/Humidity Sensor',
                    macLength: 16,
                    maxInstances: 1,
                    hubRequired: true,
                    image: SEN_TEM_HUM,
                    id: 17
                },
                {
                    type: "SEN-LIGHT-MOTION",
                    label: 'Light/Occupancy Sensor',
                    macLength: 16,
                    maxInstances: 1,
                    hubRequired: true,
                    image: SEN_LIGHT_MOTION,
                    id: 18
                },
                {
                    type: 'NB-IOT-PULSE',
                    label: 'NB-IoT Pulse Counter',
                    macLength: 12,
                    maxInstances: 3,
                    image: NB_IOT_PULSE_DELIVERED,
                    id: 20
                },

            ],
            devices: [],
            existingList: [],
            deviceType: null,
            fixedCategorys: [],
            page: 1,
            loaded: false
        };
        this.dataTypes = [
            {
                value: 'DELIVERED',
                title: 'Electricity'
            },
            {
                value: 'DELIVERED_WATER',
                title: 'Water'
            },
            {
                value: 'DELIVERED_GAS',
                title: 'Gas'
            },
            {
                value: 'DELIVERED_WATER_HOT',
                title: 'Hot Water'
            },
        ]
        this.selectSite = this.selectSite.bind(this);
        this.cancelAdd = this.cancelAdd.bind(this)
        this.checkExistingAssets = this.checkExistingAssets.bind(this)
        this.createAsset = this.createAsset.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getSiteShortcuts = this.getSiteShortcuts.bind(this)
        this.getHubShortcuts = this.getHubShortcuts.bind(this)
        this.getSites = this.getSites.bind(this)
        this.getHubs = this.getHubs.bind(this)
        this.navBack = this.navBack.bind(this)
        this.checkErrors = this.checkErrors.bind(this);
        this.handleMac = this.handleMac.bind(this);
        this.searchDevices = this.searchDevices.bind(this)
        this.handleNext = this.handleNext.bind(this)
        this.selectHub = this.selectHub.bind(this);
        this.selectDevice = this.selectDevice.bind(this)
        this.getActiveList = this.getActiveList.bind(this)
        this.getAvailableList = this.getAvailableList.bind(this)
        this.assetListChanged = this.assetListChanged.bind(this)
        this.modalClosed = false;
        this.siteOffset = 0
        this.hubOffset = 0
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        let onSitePage = window.location.href.indexOf('site/') !== -1
        if (onSitePage) {
            const regex = /(?<=site\/).{36}/;
            const match = window.location.href.match(regex);
            let s = await GridDuck.getSite({id: match[0]})
            let site = {
                id: s.id,
                title: s.name,
                locationId: s.locationId,
                subtitle: s.locationName ? s.locationName : '',
                icon: 'FaBuilding',
            }
            await this.selectSite(s, site)
        }
    }

    checkErrors() {
        let errors = {};
        let page = this.state.page
        if (page === 2) {
            let noWhiteSpace = this.state.mac?.split(' ').join('');
            if (this.state.deviceType?.macLength && (!this.state.mac || this.state.deviceType.macLength !== noWhiteSpace.length)) {
                errors['mac'] = true;
            }
            if (!this.state.deviceType) {
                errors['deviceType'] = true;
            }
            if (this.state.deviceType?.sku === 'VIRTUAL-PARENT' && !this.state.dataType) {
                errors['dataType'] = true;
            }
            if (this.state.deviceType && this.state.deviceType.hubRequired && !this.state.manualHub) {
                errors['manualHub'] = true;
            }
        }
        this.errors = errors
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    createAsset(asset) {
        let self = this;
        self.setState({addingSingleAsset: asset})
    }

    async selectHub(input, obj) {
        let self = this;
        if (!obj || !obj.id) {
            self.errors['hubLength'] = false;
            self.setState({manualHub: null});
        }
        let promises = []
        promises.push(GridDuck.getAssets({
            filters: [
                {
                    field: 'gatewayId',
                    value: obj?.id
                }
            ]
        }))

        if (obj) {
            let item = {
                itemType: 'gateway',
                itemId: obj.id
            };
            promises.push(GridDuck.createSearchHistory(item))
        }
        let res = await Promise.all(promises);
        let assets = res[0].list.length;
        self.errors['hubLength'] = (assets >= gatewayDeviceLimit);
        self.setState({manualHub: obj});
    }

    selectDevice(device, deviceObj) {
        this.setState({deviceType: deviceObj, triedToSave: false});
    }

    async getSiteShortcuts() {
        let filters = [
            {
                field: 'itemType',
                value: 'site'
            }
        ]

        let res = await GridDuck.getSearchHistorys({filters: filters})
        let shortcuts = res.list.map((r) => {
            return {
                id: r.itemId,
                title: r.name,
                subtitle: r.metadata.locationName,
                locationId: r.metadata.locationId,
                icon: 'GiAnticlockwiseRotation',
                type: 'Recent'
            }
        })
        this.setState({siteShortcuts: shortcuts})
    }

    async getHubShortcuts() {
        let site = this.state.site;

        let promises = []

        promises.push(GridDuck.getSearchHistorys({
            filters: [
                {
                    field: 'itemType',
                    value: 'gateway'
                }
            ]
        }))

        if (site) {
            let filters = [
                {
                    field: "siteId", value: site.id,
                },
                {
                    field: "locationId", value: site.locationId,
                },
            ];
            promises.push(GridDuck.getGateways({
                filters: filters
            }))
        }

        let lists = await Promise.all(promises)

        let searches = lists[0].list.map((r) => {
            return {
                id: r.itemId,
                title: r.name,
                subtitle: r.metadata.locationName,
                icon: 'GiAnticlockwiseRotation',
                type: 'Recent'
            }
        })

        let suggested = []

        if (site && site.locationId) {
            suggested = lists[1]?.list.map((s) => {
                return {
                    id: s.id,
                    title: s.name,
                    subtitle: s.locationName,
                    icon: 'FaAtom',
                    type: 'Suggested'
                }
            })
        }

        let shortcuts = suggested.concat(searches)

        this.setState({hubShortcuts: shortcuts})
    }

    async getSites(searchTerm, reset) {

        let self = this
        if (reset) this.siteOffset = 0
        let filters = []
        let limit = 25
        if (searchTerm) filters.push({
            field: 'search',
            value: searchTerm
        })
        let sitesRes = await GridDuck.getSites({filters: filters, offset: this.siteOffset,})
        let sites = sitesRes.list
            .filter(s => s._permission === 'admin')
            .map((s) => {
                return {
                    id: s.id,
                    title: s.name,
                    locationId: s.locationId,
                    subtitle: s.locationName ? s.locationName : '',
                    icon: 'FaBuilding',
                }
            })
        //updating offset to begin 'get' after latest result
        let newOffset = self.siteOffset + limit;
        self.siteOffset = newOffset
        return Promise.resolve(sites)
    }

    async getHubs(searchTerm, reset) {

        let self = this
        if (reset) self.hubOffset = 0
        let filters = []
        let limit = 25
        if (searchTerm) filters.push({
            field: 'search',
            value: searchTerm
        })
        let res = await GridDuck.getGateways({filters: filters, offset: this.hubOffset,})
        let hubs = res.list
            .filter(s => s._permission === 'admin')
            .map(
                s => ({
                    id: s.id,
                    title: s.name,
                    subtitle: s.locationName ? s.locationName : '',
                    icon: 'FaSquare',
                })
            );
        let newOffset = self.hubOffset + limit;
        self.hubOffset = newOffset
        return Promise.resolve(hubs)
    }

    async selectSite(site, siteObj) {

        if (siteObj) {
            let item = {
                itemType: 'site',
                itemId: siteObj?.id
            };
            await GridDuck.createSearchHistory(item)
        }

        this.setState({
            site: siteObj,
            loaded: true
        });
    }

    cancelAdd() {
        this.setState({addingSingleAsset: null})
    }

    searchDevices(search) {
        let self = this;
        let results;
        if (search !== '') {
            results = self.state.deviceTypes.filter(t => t.label.toLowerCase().includes(search.toLowerCase()));
        } else {
            results = self.state.deviceTypes;
        }
        return new Promise(((resolve, reject) => {
            resolve(results.map((t) => {
                return {
                    title: t.label,
                    sku: t.type,
                    value: t.id,
                    image: t.image
                }
            }))
        }))

    }

    formatMacAddress(userInput) {
        if (userInput) {
            return userInput.replace(/[^A-Fa-f0-9]/g, "").replace(/(.{4})/g, '$1 ').trim().toUpperCase();
        } else return '';
    }

    handleMac(input) {
        let inputText = this.formatMacAddress(input.target.value);
        this.setState({
            mac: inputText,
            errorMessage: '',
            triedToSave: false
        });
    }

    navBack() {
        let page = this.state.page += -1;
        this.setState({page: page})
    }

    async checkExistingAssets() {
        let self = this
        let code = this.state.mac;
        let noWhitespaceCode = code.split(' ').join('')
        let filters = [
            {
                field: 'code',
                value: noWhitespaceCode
            }
        ]
        let assetsRes = await GridDuck.getAssets({filters: filters})
        let assetList = assetsRes.list;

        if ((assetList.length > 0) && (assetList[0].sku)) {
            let deviceType = self.state.deviceTypes.find((d) => assetList[0].sku.includes(d.type));
            self.setState({existingList: assetList})
            if (assetList.length >= deviceType.maxInstances) {
                self.setState({errorMessage: `Device ${noWhitespaceCode} already exists, choose another device to install`})
                return Promise.reject('Already Exists')
            }
            return Promise.resolve()
        }
        return Promise.resolve()
    }

    async handleNext(e) {
        let self = this
        this.setState({triedToSave: true, loaded: false});
        if (Object.values(this.errors).length && Object.values(this.errors).find((e) => e === true)) return new Promise(function (resolve, reject) {
            self.setState({loaded: true})
            return reject();
        })
        if (this.state.deviceType.macLength) await self.checkExistingAssets();

        let asset = {
            sku: this.state.deviceType.sku,
            deviceType: this.state.deviceType,
            children: this.state.devices,
            assetCode: this.state.mac.split(' ').join('').toUpperCase(),
        }
        if (self.state.deviceType && self.state.deviceType.hubRequired) {
            asset.gatewayId = this.state.manualHub.id
        }
        self.setState({
            loaded: true,
            addingSingleAsset: asset,
            page: 3
        })
        return Promise.resolve()
    }

    assetListChanged(newList) {
        this.setState({devices: newList})
    }

    async getActiveList() {
        if (!this.props.item || !this.props.item.id) return Promise.resolve({list: [], total: 0})

        // else return Promise.resolve({list: self.state.newSitesList, total: self.state.newSitesList.length});
    }

    async getAvailableList(params) {
        let res = await GridDuck.getAssets(params);
        console.log(res, ' : asset res');
        this.setState({availableDevices: res.total, listLoaded: true})
        return Promise.resolve(res);
    }

    render() {
        this.checkErrors();
        let self = this;
        let page1;
        let page2;
        let nextButton;
        let noHubMessage;
        let footer = [];
        let submitButton;
        let backButton;

        //page 1
        if (this.state.page === 1) {
            if (this.state.noHubs) {
                noHubMessage = <div className={'row'} style={{marginTop: '20px'}}>
                    <p>Selected site has no hubs - please choose another site</p>
                </div>
            }
            page1 =
                <div className={'form-container'}>
                    <div className={'row'}>
                        <GdAutocomplete
                            async
                            icon
                            subtitle
                            lazyload
                            getShortcuts={self.getSiteShortcuts}
                            shortcuts={self.state.siteShortcuts}
                            getList={self.getSites}
                            name={'site'}
                            getOptionSelected={function (option, value) {
                                if (option && value) return option.id === value.id;
                            }}
                            value={this.state.site}
                            placeholder={'Select a site'}
                            onChange={this.selectSite}
                            label="Site"/>
                    </div>
                    {noHubMessage}
                </div>
            nextButton = (
                <div className={'button-container'}>
                    <Button
                        key={'next-butt'}
                        onClick={() => self.setState({page: 2})}
                        disabled={!self.state.site}
                        label={"Next"}
                        color={"gd-brand"}
                    />
                </div>);
            footer.push([nextButton])
        }

        // page 2
        if (this.state.page === 2) {
            if (this.state.loaded) {
                page2 = <div
                    className={'body-container'}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div className={'form-container'}>
                        <div className={'row'}>
                            <GdAutocomplete
                                grid
                                image
                                name={'device-type'}
                                error={this.errors['deviceType'] && this.state.triedToSave}
                                value={this.state.deviceType}
                                placeholder={'Search for a Device'}
                                options={self.state.deviceTypes.map((t) => {
                                    return {
                                        title: t.label,
                                        subtitle: t.subtitle,
                                        sku: t.type,
                                        hubRequired: t.hubRequired,
                                        macLength: t.macLength,
                                        value: t.id,
                                        image: t.image
                                    }
                                })}
                                onChange={this.selectDevice}
                                label="Device Type"/>
                        </div>
                        {this.state.deviceType && this.state.deviceType.macLength ?
                            <div className={'row'} style={{marginTop: '20px'}}>
                                <Input
                                    required
                                    error={this.errors['mac'] && this.state.triedToSave}
                                    maxLength={19}
                                    top="true"
                                    label={'MAC Address'}
                                    name={'mac'}
                                    onChange={this.handleMac}
                                    value={this.state.mac}
                                />
                            </div> : null}
                        {this.state.errorMessage ? <div className={'row'} style={{marginTop: '20px'}}>
                            <p className={'error-text'}>
                                {self.state.errorMessage}
                            </p>
                        </div> : null}
                        {this.state.deviceType && this.state.deviceType.hubRequired ?
                            <div className={'row'} style={{marginTop: '20px'}}>
                                <GdAutocomplete
                                    async
                                    icon
                                    subtitle
                                    lazyload
                                    clearOffset={() => console.log('trying to clear offset')}
                                    getShortcuts={self.getHubShortcuts}
                                    shortcuts={self.state.hubShortcuts}
                                    name={'hub'}
                                    getOptionSelected={function (option, value) {
                                        if (option && value) return option.id === value.id;
                                    }}
                                    error={(this.errors['hubLength'] || this.errors['manualHub']) && this.state.triedToSave}
                                    value={this.state.manualHub}
                                    placeholder={'Select a hub'}
                                    getList={self.getHubs}
                                    onChange={this.selectHub}
                                    label="Hub"/>
                            </div> : null}
                        <div className={'row'}>
                            {this.errors['hubLength'] && this.state.triedToSave ?
                                <p className={'error-text'}>Hub already has 17 devices</p> : null}
                        </div>
                        {this.state.deviceType?.sku === 'VIRTUAL-PARENT' ?
                            <div className={'row'} style={{marginTop: '20px'}}>
                                <Dropdown
                                    label={`Data Type`}
                                    name={`data-type`}
                                    error={this.errors['dataType'] && this.state.triedToSave}
                                    value={this.state.measurementType}
                                    fixeditems={this.dataTypes}
                                    placeholder={'Select Data Type'}
                                    onChange={(input) => {
                                        self.setState({dataType: input.target.value, listLoaded: false})
                                    }}
                                />
                            </div>
                            : null}
                    </div>
                    {this.state.deviceType?.sku === 'VIRTUAL-PARENT' && this.state.dataType ?
                        <div className={'column'}
                             style={{height: '100%', overflow: 'hidden'}}>
                            <div className={'header'}>
                                Select Children Devices
                            </div>
                            <div className={'editable-container'}>
                                <EditableList
                                    isEditing={true}
                                    key={1}
                                    noSave
                                    noCancel
                                    noitemelem={this.state.listLoaded ? <span
                                            className={'no-devices'}
                                        >No devices available at {this.state.site.title} with data type {this.dataTypes.find((t) => t.value === this.state.dataType).title}</span> :
                                        <GenericLoader/>}
                                    getActiveList={this.getActiveList}
                                    getAvailableList={this.getAvailableList}
                                    availableFilters={[
                                        {
                                            field: 'dataType',
                                            value: this.state.dataType
                                        },
                                        {
                                            field: 'siteId',
                                            value: this.state.site.id
                                        },
                                    ]}
                                    save={this.setAssets}
                                    onChange={this.assetListChanged}
                                />
                            </div>
                        </div>
                        : null}
                </div>
            } else {
                page2 = <GenericLoader/>
            }
            backButton = (
                <div className={'button-container'}>
                    <Button
                        onClick={() => {
                            self.setState({
                                page: 1,
                                mac: '',
                                dataType: null,
                                manualHub: null,
                                deviceType: null,
                                site: null,
                                triedToSave: false
                            })
                        }}
                        label={"Back"}
                        outline
                        color={"gd-grey"}
                    />
                </div>
            )
            nextButton = (
                <div className={'button-container'}>
                    <Button
                        key={'next-butt'}
                        onClick={this.handleNext}
                        progressRes
                        label={"Next"}
                        color={"gd-brand"}
                    />
                </div>);
            footer.push([nextButton, backButton])
        }

        //page 3
        if (this.state.page === 3) {

            backButton = (
                <div className={'button-container'}>
                    <Button
                        onClick={() => {
                            this.formRef.current.clearFields();
                            self.setState({
                                page: 2,
                                addingSingleAsset: null,
                                mac: '',
                                deviceType: null,
                                triedToSave: false
                            })
                        }}
                        label={"Back"}
                        outline
                        color={"gd-grey"}
                    />
                </div>
            )
            submitButton = (
                <div className={'button-container'}>
                    <Button
                        key={'submit-butt'}
                        // additionalclasses={'sm left'}
                        onClick={this.formRef.current.submitAsset}
                        progressRes={true}
                        label={"Submit"}
                        color={"gd-green"}
                    />
                </div>);
            footer.push([submitButton, backButton])
        }

        let title = 'Add Device';
        if (self.state.site && self.state.page !== 1) {
            title += ` to '${self.state.site.title}'`
        }

        return (
            <GdModal
                open={this.props.open}
                contentLabel={'Add Device'}
                title={title}
                isMobile={this.props.isMobile}
                footer={footer.length ? footer : null}
                onClose={this.closeModal}>
                <CardBody additionalclasses={'no-padding rule'}>
                    {page1}
                    {page2}
                    <div
                        className={this.state.page === 3 ? 'form-container ' : ''}
                    >
                        <DeviceForm
                            existingList={this.state.existingList}
                            ref={this.formRef}
                            hubs={this.state.hubs}
                            site={this.state.site}
                            asset={this.state.addingSingleAsset}
                            closeForm={this.closeModal}
                        />
                    </div>
                </CardBody>
            </GdModal>
        )
    }
}

export default AddManualDeviceModal;
