import React from "react";
import GridDuck from "gridduck";
import {
    Button, GdRadio, CardHeader,
    Card, EditableList, GdAutocomplete,
    Input, StandardListCell, CardBody, DateTimeRangePicker
} from "gd-react";
import {StateOptions} from "gd-react";

import Toggle from "../../components/Toggle/Toggle";
import {confirmAlert} from "react-confirm-alert";
import getFormat from "../../services/formatter";
import moment from "moment-timezone";

class AggregateData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectionType: 'organisationId'
        };
        this.selectionChanged = this.selectionChanged.bind(this);
        this.getIndividualList = this.getIndividualList.bind(this);
        this.getAvailableIndividualList = this.getAvailableIndividualList.bind(this);
        this.saveIndividualList = this.saveIndividualList.bind(this);
        this.removeDataUpdated = this.removeDataUpdated.bind(this);
        this.aggregateTelemetry = this.aggregateTelemetry.bind(this);
    }

    selectionChanged(e) {
        let self = this;
        this.setState({
            selectionType: e.target.value,
            itemId: undefined
        })
    }

    getIndividualList(retrievalParams) {
        let activeList = this.props.ddState && this.props.ddState.selectedAssets ? this.props.ddState.selectedAssets : [];
        console.log(activeList, ' : active list');
        console.log(this.props.ddState, ' : this.props.ddState');
        return Promise.resolve({list: [], total: 0});
    }

    getAvailableIndividualList(retrievalParams) {
        return GridDuck.getAssets(retrievalParams);
    }

    saveIndividualList(list) {
        console.log(list, ' : list abc');
        this.setState({
            selectedAssets: list,
            isEditing: false
        }, () => {
            if (this.props.inPage) this.props.onUpdate(list, this.state, this.filteredAssetList)
        });
        return Promise.resolve();
    }

    getList(filerType, searchTerm) {
        console.log("Get list", filerType, searchTerm);
        switch (filerType) {
            case 'assetId':
                return GridDuck.getAssets({
                    filters: [{
                        field: 'search',
                        value: searchTerm
                    }]
                }).then(function (siteList) {
                    return Promise.resolve(
                        siteList.list.map(
                            s => ({
                                id: s.id,
                                title: s.name
                            })
                        )
                    )
                })
            case 'organisationId':
                return GridDuck.getOrganisationManagements({
                    filters: [{
                        field: 'search',
                        value: searchTerm
                    }]
                }).then(function (siteList) {
                    return Promise.resolve(
                        siteList.list.map(
                            s => ({
                                id: s.id,
                                title: s.name
                            })
                        )
                    )
                })
            case 'siteId':
                return GridDuck.getSites({
                    filters: [{
                        field: 'search',
                        value: searchTerm
                    }]
                }).then(function (siteList) {
                    return Promise.resolve(
                        siteList.list.map(
                            s => ({
                                id: s.id,
                                title: s.name
                            })
                        )
                    )
                })
            case 'assetGroupId':
                return GridDuck.getGroups({
                    filters: [{
                        field: 'search',
                        value: searchTerm
                    }]
                }).then(function (groupList) {
                    return Promise.resolve(
                        groupList.list.map(
                            s => ({
                                id: s.id,
                                title: s.name
                            })
                        )
                    )
                })
            case 'siteGroupId':
                return GridDuck.getSiteGroups({
                    filters: [{
                        field: 'search',
                        value: searchTerm
                    }]
                }).then(function (groupList) {
                    return Promise.resolve(
                        groupList.list.map(
                            s => ({
                                id: s.id,
                                title: s.name
                            })
                        )
                    )
                })
            default:
                break;
        }
        return Promise.resolve([
            {
                id: 1,
                title: searchTerm
            }
        ]);
    }

    aggregateTelemetry() {
        let start, end;
        if (this.state.customRange) {
            start = this.state.start;
            end = this.state.end;
        } else {
            end = Math.round(new Date().getTime() / 1000);
            start = moment().subtract(1, 'month').startOf('month').unix();
        }
        let request = {
            groupType: this.state.selectionType,
            groupId: this.state.itemId,
            start: start,
            end: end
        };
        console.log("Creating request", request);
        return GridDuck.createAggregateTelemetryGroupRequest(request);
    }

    removeDataUpdated(dates) {
        let startUnix = new Date(dates.start).getTime() / 1000;
        let endUnix = new Date(dates.end).getTime() / 1000;
        this.setState({
            start: startUnix,
            end: endUnix
        });
    }

    render() {
        let self = this;

        let optionVals = [
            {
                label: "Devices",
                name: 'devices',
                value: 'devices'
            },
            {
                label: "Device",
                name: 'assetId',
                value: 'assetId'
            },
            {
                label: "Site",
                name: 'siteId',
                value: 'siteId'
            },
            {
                label: "Site Group",
                name: 'siteGroupId',
                value: 'siteGroupId'
            },
            {
                label: "Asset Group",
                name: 'assetGroupId',
                value: 'assetGroupId'
            },
            {
                label: "Organisation",
                name: 'organisationId',
                value: 'organisationId'
            }
        ];
        let wantedOption = optionVals.find(o => o.value === this.state.selectionType);
        let itemSelection;
        if (this.state.selectionType !== 'devices') {
            itemSelection = (<GdAutocomplete
                async
                placeholder={wantedOption.label + ' Name'}
                value={this.state.itemId}
                onChange={(ev, item) => {
                    if (item) {
                        this.setState({itemId: item.id});
                    }
                }}
                getList={(searchTerm) => self.getList(this.state.selectionType, searchTerm)}
                label={wantedOption.label + " search"}
            />);
        } else {
            itemSelection = (
                <div className={'row'} style={{marginTop: '20px', maxHeight: '350px'}}>
                    <EditableList
                        isEditing={true}
                        noCancel={true}
                        noSave={true}
                        columns={[
                            {
                                component: StandardListCell,
                                bold: true,
                                sortable: true,
                                title: 'Name',
                                field: 'name'
                            },
                            {
                                component: StandardListCell,
                                sortable: false,
                                title: 'Site Name',
                                field: 'siteName'
                            },
                        ]}
                        editingColumns={[
                            {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                        ]}
                        getActiveList={this.getIndividualList}
                        getAvailableList={this.getAvailableIndividualList}
                        onChange={this.saveIndividualList}
                    />
                </div>
            )
        }
        return (
            <div className='page not-flex'>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Re-Aggregate Data</CardHeader>
                            <CardBody>
                                <GdRadio
                                    onChange={this.selectionChanged}
                                    value={this.state.selectionType} row options={optionVals}/>
                                <br/>
                                <div className={'detail-content narrow'} style={{maxWidth: '1200px'}}>
                                    {itemSelection}
                                    <br/>
                                    <Toggle
                                        onClick={function (val) {
                                            self.setState({customRange: val === 'true'});
                                        }}
                                        inactivestatename={'Last month onwards'}
                                        activestatename={'Custom date range'}
                                        active={self.state.customRange ? 'true' : 'false'}
                                    />
                                    <br/>
                                    {self.state.customRange ?
                                        <DateTimeRangePicker nolabel='true' onChange={this.removeDataUpdated}/> : ''}
                                    {(this.state.itemId || (this.state.selectionType === 'devices' && this.state.selectedAssets && this.state.selectedAssets.length)) && (!this.state.customRange || (this.state.end && this.state.start)) ? (
                                        <Button progressRes
                                                onClick={self.aggregateTelemetry} label={'Aggregate Telemetry'}/>) : ''}
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default AggregateData;